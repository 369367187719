/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* ApiObject Page: https://www.creative-tim.com/apiobject/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { getItem, postItem, updateItem } from "../../services/api.svs";

import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
// import { Auth } from 'aws-amplify';
import { Blocks } from 'react-loader-spinner';
import { KeycloakContext } from 'contexts/KeyCloakProvider';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

function Status() {

  const context = useContext(KeycloakContext);
  const { keycloak } = context
  const token = keycloak.token

  const [IsLoaded, setIsLoaded] = useState(true);
  const [textColour, setColour] = useState("white")

  // status data
  const [isDatagrid, setIsDatagrid] = useState(true)
  const [rows, setRows] = useState([]);

  const [date] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date(date.setMonth(date.getMonth() - 1)));

  const notificationAlertRef = React.useRef(null);

  const columns = [
    {
      field: 'keycloakUsername',
      headerName: 'Username',
      width: 250,
      editable: false,
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      width: 200,
      editable: false,
    },
    {
      field: 'requestProvider',
      headerName: 'Request Provider',
      width: 150,
      editable: false,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 250,
    },
  ];

  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const getStatus = async (start, end) => {
    var min = start.substr(0,10);
    var max = end.substr(0,10) + 'T23:59:59.999';
    getItem(`/audit/status/backoffice?min=${min}&max=${max}`, token).then(resp => {
      setRows(resp.data)
      setIsDatagrid(typeof (resp.data) !== 'string')
      setIsLoaded(true)

    }).catch(resp => {
      setRows([])
      setIsLoaded(true)
      let message = (<div>
        <div>
          Error retrieved getting the data
        </div>
      </div>)
      notify("tl", message, "danger")
    })
  }

  useEffect(() => {
    setColour(document.body.classList.contains("white-content") ? "black" : "white");
    getStatus(startDate.toISOString(), endDate.toISOString())
  }, [])

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    if (newEndDate !== null && newEndDate !== undefined) {
      var date = new Date(newEndDate)
      var endDate = new Date(date.setMonth(date.getMonth() + 1, 0))
      setEndDate(endDate)
      var flag = new Date(newEndDate) - new Date(newStartDate) <= 31622400000;
      console.log(newStartDate)
      if (flag) {
        getStatus(newStartDate.toISOString(), endDate.toISOString())
      } else {
        setEndDate(new Date(new Date(newStartDate).setMonth(new Date(newStartDate).getMonth() + 12, 0)))
        let message = (<div>
          <div>
            One year maximum on datepicker. Setting to one year range.
          </div>
        </div>)
        notify("tl", message, "danger")
        getStatus(newStartDate.toISOString(), new Date(new Date(newStartDate).setMonth(new Date(newStartDate).getMonth() + 12, 0)).toISOString())
      }
    }
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button className="datepicker" onClick={onClick} ref={ref}  style={{margin: '0'}}>
      {value}
    </Button>
  ));

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <div className="content">
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h5 className="title">Status</h5>

                  <div
                    style={{ marginTop: '1em', marginRight: '2em', float: 'right' }}>
                    <DatePicker
                      closeOnScroll={(e) => e.target === document}
                      selected={startDate}
                      onChange={handleChange}
                      selectsRange
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="MM-yyyy"
                      showMonthYearPicker
                      customInput={<CustomInput />}
                    /></div>
                </CardHeader>
                <CardBody>

                  {isDatagrid ? <Box sx={{ width: '100%' }}>
                    <DataGrid
                      style={{ color: `${textColour}` }}
                      rows={rows}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10]}
                      disableRowSelectionOnClick
                    />
                  </Box> : <p>{rows}</p>}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}>
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          /></div>

      )
      }
    </>
  );
}

export default Status;

