import React, { createContext, useEffect, useState } from 'react';
import keycloakConfig from 'config/keycloak.js';
import Keycloak from 'keycloak-js';

const KeycloakContext = createContext();

const KeycloakProvider = ({ children }) => {
  const [keycloak, setKeycloak] = useState(null);
  const [initialized, setInitialized] = useState(false); // Add an initialized state

  useEffect(() => {
    const initKeycloak = async () => {
      const keycloakInstance = new Keycloak(keycloakConfig);
      try {
        await keycloakInstance.init({
          onLoad: 'check-sso',
          KeycloakResponseType: 'code',
          pkceMethod: 'S256'
        });
        setKeycloak(keycloakInstance);
        // console.log("Setting keycloak", keycloakInstance)
        setInitialized(true); // Set initialization state to true when Keycloak is initialized
      } catch (error) {
        console.error('Keycloak initialization error:', error);
      }
    };

    initKeycloak();
  }, []);


  return (
    <KeycloakContext.Provider value={{keycloak, initialized}}>
      {children}
    </KeycloakContext.Provider>
  );
};

export { KeycloakContext, KeycloakProvider };
