/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* ApiObject Page: https://www.creative-tim.com/apiobject/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useContext } from 'react';
import { getItem, postItem, updateItem } from "../services/api.svs";
import { useHistory } from "react-router-dom";

import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
// import { Auth } from 'aws-amplify';
import { Blocks } from 'react-loader-spinner';
import { KeycloakContext } from 'contexts/KeyCloakProvider';

function ApiObjectManager(props) {

  const context = useContext(KeycloakContext);
  const { keycloak } = context
  const token = keycloak.token


  const [isFilled] = useState(props.location.state !== undefined)
  const [filledApiObject] = useState(isFilled ? props.location.state : undefined)

  const [IsLoaded, setIsLoaded] = useState(true);

  const [apiProviders, setApiProviders] = useState([])

  // request object data
  const [apiObjectId] = useState(isFilled ? filledApiObject?.apiObjectId : 0);
  const [name, setName] = useState(isFilled ? filledApiObject?.apiName : '');
  const [path, setPath] = useState(isFilled ? filledApiObject?.basePath : '');
  const [apiProviderId, setApiProviderId] = useState(isFilled ? filledApiObject?.apiProviderId : 0)

  const notificationAlertRef = React.useRef(null);
  const history = useHistory();


  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    const getApiProviders = async () => {
      getItem(`/apiProvider`, token).then(resp => {
        setApiProviders(resp.data)
        setApiProviderId(resp.data[0].apiProviderId)
        setIsLoaded(true)

      }).catch(resp => {
        setApiProviders([])
        setIsLoaded(true)
        let message = (<div>
          <div>
            Error retrieved getting the data
          </div>
        </div>)
        notify("tl", message, "danger")
      })
    }
    getApiProviders()
  }, [])

  const submit = () => {
    const formData = new FormData();
    formData.append("apiName", name);
    formData.append("basePath", path);
    formData.append("apiProviderId", apiProviderId)
    const data = Object.fromEntries(formData.entries());
    if (isFilled) {
      data.apiObjectId = apiObjectId
      updateItem('/apiObject', data, token).then(resp => {
        setIsLoaded(true)
        let state = "success"

        history.push("/api-objects", state);
      }).catch(resp => {
        setIsLoaded(true)
        let state = "danger"

        history.push("/api-objects", state);
      })
    }
    else {
      console.log(apiProviderId)
      postItem('/apiObject', data, token).then(resp => {
        setIsLoaded(true)
        let state = "success"

        history.push("/api-objects", state);
      }).catch(resp => {
        setIsLoaded(true)
        let state = "danger"

        history.push("/api-objects", state);
      })
    }
  }

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <div className="content">
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h5 className="title"> {`${isFilled ? 'Update' : 'Add'}`} API Object</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>API Name</label>
                          <Input
                            placeholder="Name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                     <Col>
                     <FormGroup>
                       <label>
                         API
                       </label>
                       <Input
                         id="apiProviderSelect"
                         name="apiProvider"
                         type="select"
                         value={apiProviderId}
                         onChange={(e) => setApiProviderId(e.target.value)}
                       >
                         {apiProviders.map((item) => {
                           return (<option key={item.apiProviderId} value={item.apiProviderId}>
                             {item.providerName}
                           </option>);
                         })}
                       </Input>
                     </FormGroup>
                   </Col>
                  </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Base Path</label>
                          <Input
                            placeholder="https://path.to/api/"
                            type="text"
                            value={path}
                            onChange={(e) => setPath(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button className="btn-fill" color="primary" type="submit" onClick={submit}>
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}>
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          /></div>

      )
      }
    </>
  );
}

export default ApiObjectManager;

