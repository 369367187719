
import React, { useState, useContext } from 'react';
import { postItem, updateItem } from "../services/api.svs";
import { useHistory } from "react-router-dom";

import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { Blocks } from 'react-loader-spinner';

import { KeycloakContext } from 'contexts/KeyCloakProvider';

function ApiProviderManager(props) {

  const context = useContext(KeycloakContext);
  const { keycloak } = context
  const token = keycloak.token

  const [isFilled] = useState(props.value !== undefined)
  const [filledApiProvider] = useState(isFilled ? props.value : [])

  const [IsLoaded, setIsLoaded] = useState(true);

  // request object data
  const [apiProviderId] = useState(isFilled ? filledApiProvider.apiProviderId : 0);
  const [name, setName] = useState(isFilled ? filledApiProvider.providerName : '');
  const [email, setEmail] = useState(isFilled ? filledApiProvider.contactEmail : '');
  const [icon, setIcon] = useState(isFilled ? filledApiProvider.icon : '');
  const [label, setLabel] = useState(isFilled ? filledApiProvider.label : '');
  const [heading, setHeading] = useState(isFilled ? filledApiProvider.heading : '');
  const [description, setDescription] = useState(isFilled ? filledApiProvider.description : '');
  const [type, setType] = useState(isFilled ? filledApiProvider.type : '');

  const notificationAlertRef = React.useRef(null);
  const history = useHistory();


  // const notify = (place, message, type) => {
  //   var options = {};
  //   options = {
  //     place: place,
  //     message: (
  //       message
  //     ),
  //     type: type,
  //     icon: "tim-icons icon-bell-55",
  //     autoDismiss: 7
  //   };
  //   notificationAlertRef.current.notificationAlert(options);
  // };

  const submit = () => {
    console.log("Submitting...")
    console.log("Token no filled", token)
    const formData = new FormData();
    formData.append("providerName", name);
    formData.append("contactEmail", email);
    formData.append("icon", icon);
    formData.append("label", label);
    formData.append("description", description);
    const data = Object.fromEntries(formData.entries());
    if (isFilled) {
      formData.append("apiProviderId", apiProviderId)
    const data = Object.fromEntries(formData.entries());
      updateItem('/apiProvider', data, token).then(resp => {
        setIsLoaded(true)
        let state = "success"

        history.push("/api-providers", state);
      }).catch(resp => {
        setIsLoaded(true)
        let state = "danger"

        history.push("/api-providers", state);
      })
    }else {
      postItem('/apiProvider', data, token).then(resp => {
        setIsLoaded(true)
        let state = "success"

        history.push("/api-providers", state);
      }).catch(resp => {
        setIsLoaded(true)
        let state = "danger"

        history.push("/api-providers", state);
      })
    }
  }

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <div className="content">
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h5 className="title"> {`${isFilled ? 'Update' : 'Add'}`} API Provider</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Provider Name</label>
                          <Input
                            placeholder="Name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Contact Email</label>
                          <Input
                            placeholder="contact@mail.com"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Icon</label>
                          <Input
                            placeholder="fa user"
                            type="text"
                            value={icon}
                            onChange={(e) => setIcon(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Label</label>
                          <Input
                            placeholder="Verification Services"
                            type="text"
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Description</label>
                          <Input
                            placeholder="Description"
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button className="btn-fill" color="primary" type="submit" onClick={submit}>
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}>
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          /></div>

      )
      }
    </>
  );
}

export default ApiProviderManager;

