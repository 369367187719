import React, { useState, useEffect, useContext } from 'react';
import { deleteItem, getItem } from "../services/api.svs";
// import { Auth } from 'aws-amplify';
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input
} from "reactstrap";
import { Button } from '@aws-amplify/ui-react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Blocks } from 'react-loader-spinner';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { KeycloakContext } from 'contexts/KeyCloakProvider';

function ApiObjects(props) {
  
  const context = useContext(KeycloakContext);
  const { keycloak, initialized } = context
  const token = keycloak.token

  const [textColour, setColour] = useState("white")
  const [IsLoaded, setIsLoaded] = useState(false);
  const [apiobjects, setApiObjects] = useState([])
  const [filtered, setFiltered] = useState([])
  const [filter, setFilter] = useState(false)
  // const [show, setShow] = useState(false);
  const notificationAlertRef = React.useRef(null);
  const history = useHistory();

  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    const getApiObjects = async () => {
  
      setColour(document.body.classList.contains("white-content") ? "black" : "white");
     
      const token = keycloak.token
      // console.log("KC ABI Objectr", keycloak, token)
      getItem('/apiObject', token).then(resp => {
        setApiObjects(resp.data)
        let message = (<div>Data retrieved</div>)
        notify("tl", message, "success")
        // resp.data.forEach(element => {
        //   getItem(`/apiprovider/${element.apiProvider}`).then(resp => {
        //     const data = resp.data
        //     // setApiObjects(...element, data)
        //     apiobjects[0]
        //     // setApiObjects(apiobjects)
        //     setIsLoaded(true)
        //   }).catch(resp => {
        //     setApiObjects([])
        //     setIsLoaded(true)
        //     let message = (<div>
        //       <div>
        //         Error retrieved getting the data
        //       </div>
        //     </div>)
        //     notify("tl", message, "danger")
        //   })
        // });
        setIsLoaded(true)
      }).catch(resp => {
        setApiObjects([])
        setIsLoaded(true)
        let message = (<div>
          <div>
            Error retrieved getting the data
          </div>
        </div>)
        notify("tl", message, "danger")
      })
      //stop loading when data is fetched
    }

    if (initialized) {
      getApiObjects()
    }
    
  }, [initialized])

  const deleteRequestObject = (event) => {
    deleteItem(`/apiObject/${event}`, token).then(resp => {
        let state = "success"
        let message = (<div>
          <div>
            Deleted successfully
          </div>
        </div>)
        notify("tl", message, "success")

        history.push("/api-objects", state);

    }).catch(resp => {
        setIsLoaded(true)
        let state = "danger"
        let message = (<div>
          <div>
            Error in deletion process
          </div>
        </div>)
        notify("tl", message, "danger")

        history.push("/api-objects", state);
    })
    //stop loading when data is fetched
}

  // const onButtonClick = async (contactId) => {
  //   const token = window.localStorage["Access_Token"]
  //   requestFileItem(`storage/download/zip?id=${contactId}`, token).then(response => {
  //     const filename = response.headers.get('Content-Disposition').split('filename=')[1];
  //     let url = window.URL.createObjectURL(new Blob([response.data]));
  //     let a = document.createElement('a');
  //     a.href = url;
  //     a.download = filename;
  //     a.click();
  //   });
  // }

  //on Search Data in Table 
  const onSearchData = (e) => {
    var searchData = [];
    if (e.target.value !== "") {
      function find(arr) {
        var result = [];
        for (var i in arr) {
          if (arr[i].basePath.toLowerCase().match(e.target.value.toLowerCase()) ||
            arr[i].apiName.toLowerCase().match(e.target.value.toLowerCase())) {
            result.push(arr[i]);
          }
        }
        return result;
      }
      searchData = find(apiobjects);
      setFiltered(searchData)
      setFilter(true)
    } else {
      searchData = [];
      setFilter(searchData)
      setFilter(false)
    }
  }


  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <div className="content">
          <Row>
            <Col md="6">
              <Card style={{ position: "sticky", top: 50, backgroundColor: "rgba(30, 30, 47, 1) !important", zIndex: 0 }}>
                <CardHeader>
                  <Row>
                    <Col md="10">
                      <CardTitle tag="h4">API Objects</CardTitle>
                      <p className="category">Information for current third party objects.</p>
                    </Col>
                    <Col><Link to={{
                      pathname: '/api-object-manager',
                      // search: '?',
                      // state: item
                    }}><Button style={{ color: `${textColour}` }}>
                        <i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} />
                      </Button></Link></Col></Row>
                </CardHeader>
                <CardBody>
                  <div>
                    <Input
                      placeholder="Search by name or base path..."
                      onChange={(e) => onSearchData(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className='card-plain'>
                <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: "transparent" }}>
                  <TableContainer sx={{ maxHeight: 700 }}>
                    <Table stickyHeader aria-label="sticky table" id="table-client">
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>API Name</TableCell>
                          <TableCell>Base Path</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(filter ? filtered : apiobjects)
                          .map((item) => {
                            return (
                              <TableRow hover tabIndex={-1} key={item.apiObjectId}>
                                <TableCell style={{ color: `${textColour}` }}>{item.apiObjectId}</TableCell>
                                <TableCell style={{ color: `${textColour}` }}><div className="button-container">{item.apiName}</div></TableCell>
                                <TableCell style={{ color: `${textColour}` }}>{item.basePath}</TableCell>
                                <TableCell style={{ color: `${textColour}` }}><div className="button-container">

                                  <Link to={{
                                    pathname: '/api-object-manager',
                                    // search: '?',
                                    state: item
                                  }}><Button style={{ color: `${textColour}` }}>
                                      <i className="tim-icons icon-pencil" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                    </Button></Link>

                                  &nbsp;&nbsp;&nbsp;
                                        <Button className="btn-icon btn-round" onClick={() => {deleteRequestObject(item.apiObjectId)}}>
                                            <i className="tim-icons icon-trash-simple" style={{ color: "red" }} />
                                        </Button>
                                </div></TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}>
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          /></div>

      )
      }
    </>
  );
}

export default ApiObjects;