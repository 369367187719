/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* ApiObject Page: https://www.creative-tim.com/apiobject/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useContext, forwardRef } from 'react';

import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
} from "reactstrap";

import { Blocks } from 'react-loader-spinner';
import { KeycloakContext } from 'contexts/KeyCloakProvider';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Status from 'components/Audit/Status';
import StatusByUsername from 'components/Audit/StatusByUsername';
import Billable from 'components/Audit/Billable';
import BillableByUsername from 'components/Audit/BillableByUsername';

function Audit() {

    const context = useContext(KeycloakContext);
    const { keycloak } = context
    const token = keycloak.token

    const [IsLoaded, setIsLoaded] = useState(true);
    const [textColour, setColour] = useState("white")

    const notificationAlertRef = React.useRef(null);

    const notify = (place, message, type) => {

        var options = {};
        options = {
            place: place,
            message: (
                message
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    return (
        <>
            <div className="content">
                <Row>
                    <Col>
                        <Status />
                    </Col>
                    <Col>
                        <StatusByUsername />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Billable />
                    </Col>
                    <Col>
                        <BillableByUsername />
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Audit;