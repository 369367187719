import { getHubFromCarrier } from "@sentry/react";
import axios from "axios";



export const postItem = async (path, dataToSend, token) => {
  let config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Access-Control-Allow-Origin': "*",
      "Content-Type": "application/json",
    },
  }
  // Actual call
  return axios.post(`${process.env.REACT_APP_API_BASE}${path}`, dataToSend, config)

}

export const postFileItem = async (path, dataToSend, token) => {
  let config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      "Content-type": "application/json",
      "Content-Type": "multipart/form-data",
      'Access-Control-Allow-Origin': '*'
    }
  }
  // Actual call
  return axios.post(`${process.env.REACT_APP_API_BASE}${path}`, dataToSend, config)

}

export const getItem = (path, token) => {
  let config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Access-Control-Allow-Origin': "*"
    }
  }
  // Actual call
  return axios.get(`${process.env.REACT_APP_API_BASE}${path}`, config)

}

export const getFileItem = (path, token) => {
  let config = {
    headers: {
      // 'Authorization': `Bearer ${token}`,
      // 'Access-Control-Allow-Origin': '*'
    },
    responseType: 'blob'
  }
  // Actual call
  return axios.get(`${process.env.REACT_APP_API_BASE}${path}`, config)

}

export const requestFileItem = (path, token) => {
  let config = {
    headers: {
      // 'Authorization': `Bearer ${token}`,
      // 'Access-Control-Allow-Origin': '*'
    },
    responseType: 'blob'
  }
  // Actual call
  return axios.request(`${process.env.REACT_APP_API_BASE}${path}`, config)

}


export const updateItem = (path, dataToSend, token) => {
  let config = {
    headers: {
      'Access-Control-Allow-Origin': "*",
      'Authorization': `Bearer ${token}`
    },
  }
  // Actual call
  return axios.put(`${process.env.REACT_APP_API_BASE}${path}`, dataToSend, config)

}

export const updateFileItem = async (path, dataToSend, token) => {
  let config = {
    headers: {
      'Access-Control-Allow-Origin': "*",
      'Authorization': `Bearer ${token}`,
    }
  }
  // Actual call
  return axios.put(`${process.env.REACT_APP_API_BASE}${path}`, dataToSend, config)

}


export const deleteItem = (path, token) => {
  let config = {
    headers: {
      // 'Authorization': `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      'Authorization': `Bearer ${token}`,
    }
  }
  // Actual call
  return axios.delete(`${process.env.REACT_APP_API_BASE}${path}`, config)

}
