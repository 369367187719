import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import NonAdminLayout from "layouts/NonAdmin/NonAdmin.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";


import * as Sentry from "@sentry/react";

import { Blocks } from 'react-loader-spinner'
import '@aws-amplify/ui-react/styles.css';
import "assets/css/auth.css"
import { KeycloakProvider } from "contexts/KeyCloakProvider";
import { KeycloakContext } from "contexts/KeyCloakProvider";
import App from "App";

const root = ReactDOM.createRoot(document.getElementById("root"));

Sentry.init({
  dsn: "https://4fa51ecb9e805591fd7b06e8c5bcf7f2@o4505821672177664.ingest.sentry.io/4505832503640064",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/intouch\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


root.render(
  <KeycloakProvider>
  <App/>
  </KeycloakProvider>
);

