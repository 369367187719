/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* RequestObject Page: https://www.creative-tim.com/requestobject/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useContext } from 'react';
import { getItem, postItem, updateItem } from "../services/api.svs";
import { useHistory } from "react-router-dom";

import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
// import { Auth } from 'aws-amplify';
import { Blocks } from 'react-loader-spinner';
import { Checkbox, FormControlLabel } from '@mui/material';
import { KeycloakContext } from 'contexts/KeyCloakProvider';

function RequestObjectManager(props) {


  const context = useContext(KeycloakContext);
  const { keycloak } = context
  const token = keycloak.token

  const [isFilled] = useState(props.value !== undefined)
  const [filledRequestObject] = useState(isFilled ? props.value : [])

  const [IsLoaded, setIsLoaded] = useState(false);
  const requestTypes = ['GET', 'POST', 'UPDATE', 'DELETE', 'REQUEST']

  // request object data
  const [requestObjectId, setId] = useState(isFilled ? filledRequestObject.requestObjectId : 0);
  const [path, setPath] = useState(isFilled ? filledRequestObject.endpoint : '');
  const [apiObjects, setApiObjects] = useState([]);
  const [requestObjects, setRequestObjects] = useState([]);
  const [apiObject, setApiObject] = useState(isFilled ? filledRequestObject.apiObject : '');
  const [requestType, setRequestType] = useState('GET');
  const [pathVariable, setPathVariable] = useState('');
  const [label, setLabel] = useState('');
  const [description, setDescription] = useState('');
  const [icon, setIcon] = useState('');
  const [showFallback, setShowFallback] = useState(false);
  const [fallbackId, setFallbackId] = useState('');
  const [showAuthRequest, setShowAuthRequest] = useState(false);
  const [authRequestObjectId, setAuthRequestObjectId] = useState('');
  const [showPostRequest, setShowPostRequest] = useState(false);
  const [postRequestObjectId, setPostRequestObjectId] = useState('');
  const [csvUrl, setCsvUrl] = useState('');
  const [excelUrl, setExcelUrl] = useState('');
  const [responseBody, setResponseBody] = useState('');

  const [header, setHeader] = useState(isFilled ? filledRequestObject.header : []);
  const [params, setParams] = useState(isFilled ? filledRequestObject.params : []);
  const [body, setBody] = useState(isFilled ? filledRequestObject.body : []);
  const [code, setCode] = useState(isFilled ? filledRequestObject.code : []);

  const [headerType, setHeaderType] = useState('');
  const [bodyType, setBodyType] = useState('');
  // const [responseBodyType, setResponseBodyType] = useState('');

  const [headerDataSet, setHeaderDataSet] = useState({ keyName: '', valueDataType: '', required: false });
  const [bodyDataSet, setBodyDataSet] = useState({ keyName: '', valueDataType: '', required: false });
  // const [responseBodyDataSet, setResponseBodyDataSet] = useState({ keyName: '', valueDataType: '', required: false });
  const [codeDataSet, setCodeDataSet] = useState({ language: '', codeSnippet: '' });

  const [headerData, setHeaderData] = useState([]);
  const [currentParam, setCurrentParam] = useState('')
  const [bodyData, setBodyData] = useState([]);
  // const [responseBodyData, setResponseBodyData] = useState([]);
  const [codeData, setCodeData] = useState([]);

  const [headerDataCount, setHeaderDataCount] = useState([]);
  const [paramsCount, setParamsCount] = useState([]);
  const [bodyDataCount, setBodyDataCount] = useState([]);
  // const [responseBodyDataCount, setResponseBodyDataCount] = useState([]);
  const [codeDataCount, setCodeDataCount] = useState([]);

  // const [headerInputPairs, setHeaderInputPairs] = useState([]);
  // const [bodyInputPairs, setBodyInputPairs] = useState([]);
  // const [responseBodyInputPairs, setResponseBodyInputPairs] = useState([]);

  const notificationAlertRef = React.useRef(null);
  const history = useHistory();


  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    const getApiObjects = async () => {
      getItem(`/apiObject`, token).then(resp => {
        setApiObjects(resp.data)
        setApiObject(resp.data[0].apiObjectId)
        setIsLoaded(true)

      }).catch(resp => {
        setApiObjects([])
        setIsLoaded(true)
        let message = (<div>
          <div>
            Error retrieved getting the data
          </div>
        </div>)
        notify("tl", message, "danger")
      })
    }
    getApiObjects()
  }, [])

  const submit = () => {
    const formData = new FormData();
    formData.append("apiObjectId", apiObject);
    formData.append("path", path);
    formData.append("requestType", requestType);
    formData.append("pathVariable", pathVariable);
    formData.append("label", label);
    formData.append("description", description);
    formData.append("responseBody", responseBody);
    formData.append("icon", icon);
    formData.append("fallbackId", fallbackId);
    formData.append("authRequestObjectId", authRequestObjectId);
    formData.append("postRequestObjectId", postRequestObjectId);
    formData.append("csvUrl", csvUrl);
    formData.append("excelUrl", excelUrl);
    // formData.append("params", [params]);
    setHeaderInfo()
    setBodyInfo()
    // setResponseBodyInfo()
    const data = Object.fromEntries(formData.entries());
    data.header = { type: headerType, data: headerData };
    data.body = { type: bodyType, data: bodyData };
    // data.responseBody = { type: responseBodyType, data: responseBodyData };
    data.code = codeData;
    data.params = params;
    if (isFilled) {
      data.requestObjectId = requestObjectId
      updateItem('/requestObject', data, token).then(resp => {
        setIsLoaded(true)
        let state = "success"
        history.push("/request-objects", state);
      }).catch(resp => {
        setIsLoaded(true)
        let state = "danger"
        history.push("/request-objects", state);
      })
    }
    else {
      postItem('/requestObject', data, token).then(resp => {
        setIsLoaded(true)
        let state = "success"
        history.push("/request-objects", state);
      }).catch(resp => {
        setIsLoaded(true)
        let state = "danger"
        history.push("/request-objects", state);
      })
    }
  }

  // auth request object
  const toggleShowAuthRequest = () => {
    {
      !showAuthRequest ? (
        getItem(`/requestObject`, token).then(resp => {
          console.log(resp.data)
          setRequestObjects(resp.data)
          // setFallbackId(resp.data[0].id)
          setAuthRequestObjectId(resp.data[0].requestObjectId)
          setIsLoaded(true)

        }).catch(resp => {
          setRequestObjects([])
          setAuthRequestObjectId('')
          setIsLoaded(true)
          let message = (<div>
            <div>
              Error retrieved getting the data
            </div>
          </div>)
          notify("tl", message, "danger")
        })) : (setAuthRequestObjectId(''))
    }
    setShowAuthRequest(showAuthRequest ? false : true)
  }

  // post request object
  const toggleShowPostRequest = () => {
    {
      !showPostRequest ? (
        getItem(`/requestObject`, token).then(resp => {
          console.log(resp.data)
          setRequestObjects(resp.data)
          // setFallbackId(resp.data[0].id)
          setPostRequestObjectId(resp.data[0].requestObjectId)
          setIsLoaded(true)

        }).catch(resp => {
          setRequestObjects([])
          setPostRequestObjectId('')
          setIsLoaded(true)
          let message = (<div>
            <div>
              Error retrieved getting the data
            </div>
          </div>)
          notify("tl", message, "danger")
        })) : (setPostRequestObjectId(''))
    }
    setShowPostRequest(showPostRequest ? false : true)
  }

  // fallback object
  const toggleShowFallback = () => {
    {
      !showFallback ? (
        getItem(`/requestObject`, token).then(resp => {
          console.log(resp.data)
          setRequestObjects(resp.data)
          setFallbackId(resp.data[0].requestObjectId)
          // setAuthRequestObjectId(resp.data[0].id)
          setIsLoaded(true)

        }).catch(resp => {
          setRequestObjects([])
          setFallbackId('')
          setIsLoaded(true)
          let message = (<div>
            <div>
              Error retrieved getting the data
            </div>
          </div>)
          notify("tl", message, "danger")
        })) : (setFallbackId(''))
    }
    setShowFallback(showFallback ? false : true)
  }

  // headers
  const addHeaderPairInput = () => {
    setHeaderDataCount(prevCount => [...prevCount, prevCount.length + 1])
  }

  const removeHeaderPairInput = () => {
    if (headerDataCount.length === 1) {
      setHeaderDataCount([])
    } else {
      setHeaderDataCount(prevCount => [...prevCount.slice(0, -2), prevCount.length - 1])
    }
  }

  const setCurrentHeaderKey = (e) => {
    setHeaderDataSet({ ...headerDataSet, keyName: e.target.value })
  }

  const setCurrentHeaderValueType = (e) => {
    setHeaderDataSet({ ...headerDataSet, valueDataType: e.target.value })

  }

  const setCurrentHeaderRequired = (e) => {
    setHeaderDataSet({ ...headerDataSet, required: e.target.checked })
  }

  const setHeaderInfo = () => {
    setHeader({ type: headerType, data: headerData })
  }

  const addHeaderPair = () => {
    headerData.push(headerDataSet);
  }

  // params
  const addParamInput = () => {
    setParamsCount(prevCount => [...prevCount, prevCount.length + 1])
  }

  const removeParamInput = () => {
    if (paramsCount.length === 1) {
      setParamsCount([])
    } else {
      setParamsCount(prevCount => [...prevCount.slice(0, -2), prevCount.length - 1])
    }
  }

  const addParam = () => {
    params.push(currentParam);
  }

  // body
  const addBodyPairInput = () => {
    setBodyDataCount(prevCount => [...prevCount, prevCount.length + 1])
  }

  const removeBodyPairInput = () => {
    if (bodyDataCount.length === 1) {
      setBodyDataCount([])
    } else {

      setBodyDataCount(prevCount => [...prevCount.slice(0, -2), prevCount.length - 1])
    }
  }

  const setCurrentBodyKey = (e) => {
    setBodyDataSet({ ...bodyDataSet, keyName: e.target.value })
  }

  const setCurrentBodyValueType = (e) => {
    setBodyDataSet({ ...bodyDataSet, valueDataType: e.target.value })

  }

  const setCurrentBodyRequired = (e) => {
    setBodyDataSet({ ...bodyDataSet, required: e.target.checked })
  }

  const setBodyInfo = () => {
    setBody({ type: bodyType, data: bodyData })
  }

  const addBodyPair = () => {
    bodyData.push(bodyDataSet);
  }

  // responseBody
  // const addResponseBodyPairInput = () => {
  //   setResponseBodyDataCount(prevCount => [...prevCount, prevCount.length + 1])
  // }

  // const removeResponseBodyPairInput = () => {
  //   if (responseBodyDataCount.length === 1) {
  //     setResponseBodyDataCount([])
  //   } else {
  //     setResponseBodyDataCount(prevCount => [...prevCount.slice(0, -2), prevCount.length - 1])
  //   }
  // }

  // const setCurrentResponseBodyKey = (e) => {
  //   setResponseBodyDataSet({ ...responseBodyDataSet, keyName: e.target.value })
  // }

  // const setCurrentResponseBodyValueType = (e) => {
  //   setResponseBodyDataSet({ ...responseBodyDataSet, valueDataType: e.target.value })
  // }

  // const setCurrentResponseBodyRequired = (e) => {
  //   setResponseBodyDataSet({ ...responseBodyDataSet, required: e.target.checked })
  // }

  // const setResponseBodyInfo = () => {
  //   setResponseBody({ type: responseBodyType, data: responseBodyData })
  // }

  // const addResponseBodyPair = () => {
  //   responseBodyData.push(responseBodyDataSet);
  // }

  // codeData
  const addCodeDataPairInput = () => {
    setCodeDataCount(prevCount => [...prevCount, prevCount.length + 1])
  }

  const removeCodeDataPairInput = () => {
    if (codeDataCount.length === 1) {
      setCodeDataCount([])
    } else {
      setCodeDataCount(prevCount => [...prevCount.slice(0, -2), prevCount.length - 1])
    }
  }

  const setCurrentCodeLanguage = (e) => {
    setCodeDataSet({ ...codeDataSet, language: e.target.value })
  }

  const setCurrentCodeSnippet = (e) => {
    setCodeDataSet({ ...codeDataSet, codeSnippet: e.target.value })
  }

  const addCodeDataPair = () => {
    codeData.push(codeDataSet);
  }

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <div className="content">
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h5 className="title">Add RequestObject</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>
                            API
                          </label>
                          <Input
                            id="apiObjectSelect"
                            name="apiObject"
                            type="select"
                            value={apiObject}
                            onChange={(e) => setApiObject(e.target.value)}
                          >
                            {apiObjects.map((item) => {
                              return (<option key={item.apiObjectId} value={item.apiObjectId}>
                                {item.apiName}
                              </option>);
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label>Endpoint</label>
                          <Input
                            placeholder="/endpoint"
                            type="text"
                            value={path}
                            onChange={(e) => setPath(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label>Label</label>
                          <Input
                            placeholder="Label"
                            type="text"
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Request Type</label>
                          <Input
                            id="apiObjectSelect"
                            name="apiObject"
                            type="select"
                            value={requestType}
                            onChange={(e) => setRequestType(e.target.value)}
                          >
                            {requestTypes.map((item) => {
                              return (<option key={item} value={item}>
                                {item}
                              </option>);
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label>Path Variable</label>
                          <Input
                            id="pathVariable"
                            placeholder="path variable"
                            type="text"
                            onChange={(e) => setPathVariable(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label>Icon</label>
                          <Input
                            placeholder="fa icon"
                            type="text"
                            value={icon}
                            onChange={(e) => setIcon(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Description</label>
                          <Input
                            placeholder="Brief description"
                            type="textarea"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>CSV URL</label>
                          <Input
                            placeholder="https://link.to/csv_location"
                            type="text"
                            value={csvUrl}
                            onChange={(e) => setCsvUrl(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label>Excel URL</label>
                          <Input
                            placeholder="https://link.to/excel_location"
                            type="text"
                            value={excelUrl}
                            onChange={(e) => setExcelUrl(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <CardHeader><h5 className="title">Link to other Request Objects</h5></CardHeader>
                    <Row>
                      <Col>
                        <Button onClick={toggleShowFallback}>Fallback</Button>
                      </Col>
                      <Col>
                        <Button onClick={toggleShowAuthRequest}>AuthObject</Button>
                      </Col>
                      <Col>
                        <Button onClick={toggleShowPostRequest}>PostObject</Button>
                      </Col>
                    </Row>
                    <Row>
                      {showFallback ? <Col>
                        <FormGroup>
                          <label>Fallback</label>
                          <Input
                            id="fallbackSelect"
                            name="fallbackObject"
                            type="select"
                            value={fallbackId}
                            onChange={(e) => setFallbackId(e.target.value)}
                          >
                            {requestObjects.map((item) => {
                              return (<option key={item.requestObjectId} value={item.requestObjectId}>
                                {item.endpointDescription}
                              </option>);
                            })}
                          </Input>
                        </FormGroup>
                      </Col> : <></>}
                      {showAuthRequest ? <Col>
                        <FormGroup>
                          <label>Auth Object</label>
                          <Input
                            id="authRequestObjectSelect"
                            name="authRequestObjectObject"
                            type="select"
                            value={authRequestObjectId}
                            onChange={(e) => setAuthRequestObjectId(e.target.value)}
                          >
                            {requestObjects.map((item) => {
                              return (<option key={item.requestObjectId} value={item.requestObjectId}>
                                {item.label}
                              </option>);
                            })}
                          </Input>
                        </FormGroup>
                      </Col> : <></>}
                      {showPostRequest ? <Col>
                        <FormGroup>
                          <label>Post Object</label>
                          <Input
                            id="postRequestObjectSelect"
                            name="postRequestObjectObject"
                            type="select"
                            value={postRequestObjectId}
                            onChange={(e) => setPostRequestObjectId(e.target.value)}
                          >
                            {requestObjects.map((item) => {
                              return (<option key={item.requestObjectId} value={item.requestObjectId}>
                                {item.label}
                              </option>);
                            })}
                          </Input>
                        </FormGroup>
                      </Col> : <></>}
                    </Row>

                    <CardHeader><h5 className="title">Headers</h5></CardHeader>
                    <Row>
                      <Col width="50%">
                        <FormGroup>
                          <label>Type</label>
                          <Input
                            id="exampleText"
                            placeholder="application/json"
                            type="text"
                            onChange={(e) => setHeaderType(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {headerDataCount
                      .map((index) => {
                        return (
                          <div>
                            <Row>
                              <Col>
                                <label>Header Key</label></Col>
                              <Col>
                                <label>Header Value Type</label></Col>
                              <Col></Col>
                            </Row>
                            <Row key={index} width="100%">
                              <Col>
                                <FormGroup>
                                  <Input
                                    id="key"
                                    placeholder="Authorization"
                                    type="text"
                                    onChange={(e) => setCurrentHeaderKey(e, index)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Input
                                    id="valueType"
                                    placeholder="Basic Auth"
                                    type="text"
                                    onChange={(e) => setCurrentHeaderValueType(e, index)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox onChange={setCurrentHeaderRequired} />
                                    }
                                    label="Required"
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <Button onClick={addHeaderPair}><i className="tim-icons icon-check-2" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                                <Button onClick={removeHeaderPairInput}><i className="tim-icons icon-simple-remove" style={{ color: "rgba(222, 35, 35, 1)" }} /></Button>
                              </Col>
                            </Row>
                          </div>
                        )
                      })}
                    <Row>
                      <Col>
                        <Button onClick={addHeaderPairInput}><i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                      </Col>
                    </Row>
                    {/* path variable */}

                    {/* params */}
                    <CardHeader><h5 className="title">Params</h5></CardHeader>

                    {paramsCount
                      .map((index) => {
                        return (
                          <div>
                            <Row>
                              <Col>
                                <label>Param Name</label></Col>
                              <Col></Col>
                            </Row>
                            <Row key={index} width="100%">
                              <Col>
                                <FormGroup>
                                  <Input
                                    id="key"
                                    placeholder="SessionId"
                                    type="text"
                                    onChange={(e) => setCurrentParam(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>

                              <Col>
                                <Button onClick={addParam}><i className="tim-icons icon-check-2" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                                <Button onClick={removeParamInput}><i className="tim-icons icon-simple-remove" style={{ color: "rgba(222, 35, 35, 1)" }} /></Button>
                              </Col>
                            </Row>
                          </div>
                        )
                      })}
                    <Row>
                      <Col>
                        <Button onClick={addParamInput}><i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                      </Col>
                    </Row>
                    {/* body */}
                    <CardHeader><h5 className="title">Body</h5></CardHeader>
                    <Row>
                      <Col width="50%">
                        <FormGroup>
                          <label>Type</label>
                          <Input
                            id="exampleText"
                            placeholder="application/json"
                            type="text"
                            onChange={(e) => setBodyType(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {bodyDataCount
                      .map((index) => {
                        return (
                          <div>
                            <Row>
                              <Col>
                                <label>Body Key</label></Col>
                              <Col>
                                <label>Body Value Type</label></Col>
                              <Col></Col>
                            </Row>
                            <Row key={index} width="100%">
                              <Col>
                                <FormGroup>
                                  <Input
                                    id="key"
                                    placeholder="Name"
                                    type="text"
                                    onChange={(e) => setCurrentBodyKey(e, index)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Input
                                    id="valueType"
                                    placeholder="String"
                                    type="text"
                                    onChange={(e) => setCurrentBodyValueType(e, index)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox onChange={setCurrentBodyRequired} />
                                    }
                                    label="Required"
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <Button onClick={addBodyPair}><i className="tim-icons icon-check-2" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                                <Button onClick={removeBodyPairInput}><i className="tim-icons icon-simple-remove" style={{ color: "rgba(222, 35, 35, 1)" }} /></Button>
                              </Col>
                            </Row>
                          </div>
                        )
                      })}
                    <Row>
                      <Col>
                        <Button onClick={addBodyPairInput}><i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                      </Col>
                    </Row>
                    {/* responseBody */}

                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Response Body</label>
                          <Input
                            placeholder="Response Body Json"
                            type="textarea"
                            value={responseBody}
                            onChange={(e) => setResponseBody(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* responseBody
                    <CardHeader><h5 className="title">Response Body</h5></CardHeader>
                    <Row>
                      <Col width="50%">
                        <FormGroup>
                          <label>Type</label>
                          <Input
                            id="exampleText"
                            placeholder="application/json"
                            type="text"
                            onChange={(e) => setResponseBodyType(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {responseBodyDataCount
                      .map((index) => {
                        return (
                          <div>
                            <Row>
                              <Col>
                                <label>Response Body Key</label></Col>
                              <Col>
                                <label>Response Body Value Type</label></Col>
                              <Col></Col>
                            </Row>
                            <Row key={index} width="100%">
                              <Col>
                                <FormGroup>
                                  <Input
                                    id="key"
                                    placeholder="Name"
                                    type="text"
                                    onChange={(e) => setCurrentResponseBodyKey(e, index)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Input
                                    id="valueType"
                                    placeholder="String"
                                    type="text"
                                    onChange={(e) => setCurrentResponseBodyValueType(e, index)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox onChange={setCurrentResponseBodyRequired} />
                                    }
                                    label="Required"
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <Button onClick={addResponseBodyPair}><i className="tim-icons icon-check-2" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                                <Button onClick={removeResponseBodyPairInput}><i className="tim-icons icon-simple-remove" style={{ color: "rgba(222, 35, 35, 1)" }} /></Button>
                              </Col>
                            </Row>
                          </div>
                        )
                      })}
                    <Row>
                      <Col>
                        <Button onClick={addResponseBodyPairInput}><i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                      </Col>
                    </Row> */}

                    {/* code */}
                    <CardHeader><h5 className="title">Code</h5></CardHeader>
                    {codeDataCount
                      .map((index) => {
                        return (
                          <div>
                            <Row>
                              <Col>
                                <label>Language</label></Col>
                              <Col>
                                <label>Code Snippet</label></Col>
                              <Col></Col>
                            </Row>
                            <Row key={index} width="100%">
                              <Col>
                                <FormGroup>
                                  <Input
                                    id="key"
                                    placeholder="Java"
                                    type="text"
                                    onChange={(e) => setCurrentCodeLanguage(e, index)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Input
                                    id="valueType"
                                    placeholder="String"
                                    type="textarea"
                                    onChange={(e) => setCurrentCodeSnippet(e, index)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <Button onClick={addCodeDataPair}><i className="tim-icons icon-check-2" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                                <Button onClick={removeCodeDataPairInput}><i className="tim-icons icon-simple-remove" style={{ color: "rgba(222, 35, 35, 1)" }} /></Button>
                              </Col>
                            </Row>
                          </div>
                        )
                      })}
                    <Row>
                      <Col>
                        <Button onClick={addCodeDataPairInput}><i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button className="btn-fill" color="primary" type="submit" onClick={submit}>
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}>
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          /></div>

      )
      }
    </>
  );
}

export default RequestObjectManager;

