import React, { useState, useEffect, useContext } from 'react';
import { getItem } from "../services/api.svs";
// import { Auth } from 'aws-amplify';
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input
} from "reactstrap";
import { Button } from '@aws-amplify/ui-react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Blocks } from 'react-loader-spinner';
import { KeycloakContext } from 'contexts/KeyCloakProvider';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
function RequestObjects(props) {

  const context = useContext(KeycloakContext);
  const { keycloak } = context
  const history = useHistory();

  const [textColour, setColour] = useState("white")
  const [IsLoaded, setIsLoaded] = useState(false);
  const [requestobjects, setRequestObjects] = useState([])
  const [filtered, setFiltered] = useState([])
  const [filter, setFilter] = useState(false)
  // const [show, setShow] = useState(false);
  const notificationAlertRef = React.useRef(null);

  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    const getRequestObjects = async () => {
      setColour(document.body.classList.contains("white-content") ? "black" : "white");
      const token = keycloak.token
      getItem('/requestObject/filtered', token).then(resp => {
        setRequestObjects(resp.data)
        let message = (<div>Data retrieved</div>)
        notify("tl", message, "success")
        setIsLoaded(true)
      }).catch(resp => {
        setRequestObjects([])
        setIsLoaded(true)
        let message = (<div>
          <div>
            Error retrieved getting the data
          </div>
        </div>)
        notify("tl", message, "danger")
      })
      //stop loading when data is fetched
    }
    getRequestObjects()
  }, [])

  const generateYAML = () => {
    const token = keycloak.token
    getItem(`/documentation`, token).then(resp => {
        setIsLoaded(true)
        let message = (<div>YAML successfully generated.</div>)
        notify("tl", message, "success")
        history.push("/request-objects", "success");
    }).catch(resp => {
        setIsLoaded(true)
        console.log(resp)
        let message = (<div>YAML not generated. Error: {resp.code} - {resp.message}</div>)
        notify("tl", message, "danger")
        history.push("/request-objects", "danger");
    })
}

  // const onButtonClick = async (contactId) => {
  //   const token = window.localStorage["Access_Token"]
  //   requestFileItem(`storage/download/zip?id=${contactId}`, token).then(response => {
  //     const filename = response.headers.get('Content-Disposition').split('filename=')[1];
  //     let url = window.URL.createObjectURL(new Blob([response.data]));
  //     let a = document.createElement('a');
  //     a.href = url;
  //     a.download = filename;
  //     a.click();
  //   });
  // }

  //on Search Data in Table 
  const onSearchData = (e) => {
    var searchData = [];
    if (e.target.value !== "") {
      function find(arr) {
        var result = [];
        for (var i in arr) {
          var provider = arr[i].provider;
          var api = arr[i].api;
          var endpoint = arr[i].endpoint;
          var label = arr[i].label;
          if ((provider !== undefined && provider !== null ? provider.toLowerCase().match(e.target.value.toLowerCase()) : false) ||
            (api !== undefined && api !== null ? api.toLowerCase().match(e.target.value.toLowerCase()) : false) ||
            (endpoint !== undefined && endpoint !== null ? endpoint.toLowerCase().match(e.target.value.toLowerCase()) : false) ||
            (label !== undefined && label !== null ? label.toLowerCase().match(e.target.value.toLowerCase()) : false)) {
            result.push(arr[i]);
          }
        }
        return result;
      }
      searchData = find(requestobjects);
      setFiltered(searchData)
      setFilter(true)
    } else {
      searchData = [];
      setFilter(searchData)
      setFilter(false)
    }
  }


  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <div className="content">
          <Row>
            <Col md="6">
              <Card style={{ position: "sticky", top: 50, backgroundColor: "rgba(30, 30, 47, 1) !important", zIndex: 0 }}>
                <CardHeader>
                  <Row>
                    <Col md="9">
                      <CardTitle tag="h4">Request Objects</CardTitle>
                      <p className="category">Data for available APIs.</p>
                    </Col>
                    <Col><Button className="btn-icon btn-round" onClick={() => generateYAML()}>
                      <i className="tim-icons icon-paper" style={{ color: "rgba(75, 192, 192, 1)" }} />
                    </Button>&nbsp;&nbsp;&nbsp;
                      <Link to={{
                        pathname: '/request-object-manager',
                        // search: '?',
                        // state: item
                      }}><Button style={{ color: `${textColour}` }}>
                          <i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} />
                        </Button></Link></Col></Row>
                </CardHeader>
                <CardBody>
                  <div>
                    <Input
                      placeholder="Search by endpoint, endpoint description, api name or provider..."
                      onChange={(e) => onSearchData(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className='card-plain'>
                <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: "transparent" }}>
                  <TableContainer sx={{ maxHeight: 700 }}>
                    <Table stickyHeader aria-label="sticky table" id="table-client">
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Provider</TableCell>
                          <TableCell>API</TableCell>
                          <TableCell>URL</TableCell>
                          <TableCell>Endpoint</TableCell>
                          <TableCell>Label</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(filter ? filtered : requestobjects)
                          .map((item) => {
                            return (
                              <TableRow hover tabIndex={-1} key={item.requestObjectId}>
                                <TableCell style={{ color: `${textColour}` }}>{item.requestObjectId}</TableCell>
                                <TableCell style={{ color: `${textColour}` }}>{item.provider}</TableCell>
                                <TableCell style={{ color: `${textColour}` }}>{item.api}</TableCell>
                                <TableCell style={{ color: `${textColour}` }}>{item.url}</TableCell>
                                <TableCell>
                                  <div className="button-container">

                                    <Link to={{
                                      pathname: '/request-object',
                                      // search: '?',
                                      state: item
                                    }}><Button style={{ color: `${textColour}` }}>
                                        {item.endpoint}
                                      </Button></Link>
                                  </div>
                                </TableCell>
                                <TableCell style={{ color: `${textColour}` }}>{item.label}</TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}>
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          /></div>

      )
      }
    </>
  );
}

export default RequestObjects;