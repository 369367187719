import React, { useState, useEffect, useContext } from 'react';
import { getItem, postItem, deleteItem } from "../services/api.svs";
import { Auth } from 'aws-amplify';
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom";
import Logo from '../assets/img/react-logo.png';
import { Link } from "react-router-dom";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Label,
    FormText,
} from "reactstrap";
import { Button } from '@aws-amplify/ui-react';
import RequestObjectManager from './RequestObjectManager';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import RequestObjectLimitedManager from './RequestObjectLimitedManager';
import { KeycloakContext } from 'contexts/KeyCloakProvider';
import RequestObjectDataManager from './RequestObjectDataManager';

function RequestObject(props) {

    const context = useContext(KeycloakContext);
    const { keycloak } = context
    const token = keycloak.token

    const [textColour, setColour] = useState("white")
    const [IsLoaded, setIsLoaded] = useState(false);
    const [requestobject, setRequestObject] = useState([])
    const [id, setId] = useState(props.location.state.requestObjectId != null && props.location.state.requestObjectId != undefined ? props.location.state.requestObjectId : props.location.state.fallbackId)
    const [fallback, setFallback] = useState([])
    const [hasFallback, setHasFallback] = useState(false)
    const [authRequestObject, setAuthRequestObject] = useState([])
    const [hasAuthRequestObject, setHasAuthRequestObject] = useState(props.location.state.authRequestObjectId !== null && props.location.state.authRequestObjectId !== undefined)
    console.log(hasAuthRequestObject)
    const [authType, setAuthType] = useState([])
    const [username, setUsername] = useState([])
    const [password, setPassword] = useState([])
    const [authToken, setAuthToken] = useState([])
    const [key, setKey] = useState([])
    const [value, setValue] = useState([])
    const [requestHeaderPrefix, setRequestHeaderPrefix] = useState([])
    // const [fallback, setFallback] = useState([])
    const [showUpdate, setShowUpdate] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showUpdateAuth, setShowUpdateAuth] = useState(false);
    const [showSetHeader, setShowSetHeader] = useState(false);
    const [headerKey, setHeaderKey] = useState('');
    const [headerValue, setHeaderValue] = useState('')
    const [showSetBody, setShowSetBody] = useState(false);
    const [bodyKey, setBodyKey] = useState('');
    const [bodyType, setBodyType] = useState('');
    const [bodyValue, setBodyValue] = useState('')
    const [showUpdateBody, setShowUpdateBody] = useState(false);
    const [bodyUpdateKey, setBodyUpdateKey] = useState('');
    const [bodyUpdateType, setBodyUpdateType] = useState('');
    const [bodyUpdateId, setBodyUpdateId] = useState('')
    const [showUpdateHeader, setShowUpdateHeader] = useState(false);
    const [headerUpdateKey, setHeaderUpdateKey] = useState('');
    const [headerUpdateType, setHeaderUpdateType] = useState('');
    const [headerUpdateId, setHeaderUpdateId] = useState('')
    const [showUpdateResponseBody, setShowUpdateResponseBody] = useState(false);
    const [responseBodyUpdateKey, setResponseBodyUpdateKey] = useState('');
    const [responseBodyUpdateType, setResponseBodyUpdateType] = useState('');
    const [responseBodyUpdateId, setResponseBodyUpdateId] = useState('')
    const notificationAlertRef = React.useRef(null);
    const history = useHistory();

    const notify = (place, message, type) => {
        //  type = "primary";
        // type = "success";
        // type = "danger";
        // type = "warning";
        // type = "info";

        var options = {};
        options = {
            place: place,
            message: (
                message
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    useEffect(() => {
        const getRequestObject = async () => {
            const token = keycloak.token
            getItem(`/requestObject/filtered/${id}`, token).then(resp => {
                // console.log(resp.data)
                setRequestObject(resp.data)
                setHasFallback(resp.data.fallbackId !== null && resp.data.fallbackId !== undefined && resp.data.fallbackId !== '')
                setHasAuthRequestObject(resp.data.authRequestObjectId !== null && resp.data.authRequestObjectId !== undefined && resp.data.authRequestObjectId !== '')
                if (resp.data.fallbackId !== null && resp.data.fallbackId !== undefined && resp.data.fallbackId !== '') {
                    getItem(`/requestObject/filtered/${resp.data.fallbackId}`, token).then(resp => {
                        // console.log("fallback: ", resp.data)
                        setFallback(resp.data)
                    }).catch(resp => {
                        setFallback([])
                        setIsLoaded(false)
                        let message = (<div>
                            <div>
                                Error retrieved getting the data
                            </div>
                        </div>)
                        notify("tl", message, "danger")
                    })
                }
                if (resp.data.authRequestObjectId !== null && resp.data.authRequestObjectId !== undefined && resp.data.authRequestObjectId !== '') {
                    // console.log("fetching", resp.data.authRequestObjectId)
                    getItem(`/requestObject/filtered/${resp.data.authRequestObjectId}`, token).then(resp => {
                        // console.log("authRequestObjectId: ", resp.data)
                        setAuthRequestObject(resp.data)
                    }).catch(resp => {
                        setAuthRequestObject([])
                        setIsLoaded(false)
                        let message = (<div>
                            <div>
                                Error retrieved getting the data
                            </div>
                        </div>)
                        notify("tl", message, "danger")
                    })
                }
                setIsLoaded(true)

            }).catch(resp => {
                setRequestObject([])
                setIsLoaded(false)
                let message = (<div>
                    <div>
                        Error retrieved getting the data
                    </div>
                </div>)
                notify("tl", message, "danger")
            })
        }
        getRequestObject()
    }, [])

    // const getFullDataForUpdate = async () => {
    //     const token = keycloak.token
    //         getItem(`/requestobject/${props.location.state.requestObjectId}`, token).then(resp => {
    //             setRequestObjectUnfiltered(resp.data)
    //             setIsLoaded(true)

    //         }).catch(resp => {
    //             setRequestObjectUnfiltered([])
    //             setIsLoaded(true)
    //             let message = (<div>
    //                 <div>
    //                     Error retrieved getting the data
    //                 </div>
    //             </div>)
    //             notify("tl", message, "danger")
    //         })
    //     }



    // const onButtonClick = () => {
    //     const token = window.localStorage["Access_Token"]
    //     getFileItem(`storage/download/client?id=${props.location.state.contactId}`, token).then(response => {
    //         const filename = response.headers.get('Content-Disposition').split('filename=')[1];
    //         // response.blob().then(blob => {
    //         let url = window.URL.createObjectURL(new Blob([response.data]));
    //         let a = document.createElement('a');
    //         a.href = url;
    //         a.download = filename;
    //         a.click();
    //         //   }).catch(resp => {
    //         //     console.log(resp)
    //         // });
    //     });
    // }

    const toggleShowUpdate = () => {
        setShowUpdate(showUpdate ? false : true)
    }

    const toggleShowAdd = () => {
        setShowAdd(showAdd ? false : true)
    }

    const toggleShowUpdateAuth = () => {
        setShowUpdateAuth(showUpdateAuth ? false : true)
    }

    const toggleShowSetHeader = (keyName) => {
        setHeaderKey(keyName)
        setShowSetHeader(showSetHeader ? false : true)
    }

    const toggleShowSetBody = (keyName, type) => {
        setBodyKey(keyName)
        setBodyType(type)
        setShowSetBody(showSetBody ? false : true)
    }

    const toggleShowUpdateBody = (item) => {
        setBodyUpdateId(item.id)
        setBodyUpdateKey(item.keyName)
        setBodyUpdateType(item.valueDataType)
        setShowUpdateBody(showUpdateBody ? false : true)
    }

    const toggleShowUpdateHeader = (item) => {
        setHeaderUpdateId(item.id)
        setHeaderUpdateKey(item.keyName)
        setHeaderUpdateType(item.valueDataType)
        setShowUpdateHeader(showUpdateHeader ? false : true)
    }

    const toggleShowUpdateResponseBody = (item) => {
        setResponseBodyUpdateId(item.id)
        setResponseBodyUpdateKey(item.keyName)
        setResponseBodyUpdateType(item.valueDataType)
        setShowUpdateResponseBody(showUpdateResponseBody ? false : true)
    }

    const saveAuthObject = () => {
        // console.log(requestobject)
        const formData = new FormData();

        if (requestobject.auth) {
            const match = requestobject.auth.match(/id=([^,\]]+)/);
            if (match) {
                const idValue = match[1];
                // console.log(idValue);
                formData.append("id", idValue);
            } else {
                console.log("No 'id' found in the string.");
            }
        }
        formData.append("requestObjectId", id);

        formData.append("authType", requestobject.authType);
        formData.append("username", username);
        formData.append("password", password);
        formData.append("authToken", authToken);
        formData.append("requestHeaderPrefix", requestHeaderPrefix);
        formData.append("key", key);
        formData.append("value", value);

        // console.log("Before to....")
        // console.log(formData)
        const formDataObject = {};
        formData.forEach((value, key) => {
            formDataObject[key] = value;
        });
        // console.log("Updating to....")
        // console.log(formDataObject)
        // console.log(token)
        postItem(`/requestObject/auth`, formDataObject, token).then(resp => {
            setShowUpdateAuth(false)
            setIsLoaded(true)
            let state = "success"
            history.push("/request-object", state);

        }).catch(resp => {
            setIsLoaded(true)
            let state = "danger"

            history.push("/request-object", state);
        })
    }

    const saveHeaderObject = () => {
        // console.log(requestobject)
        const formData = new FormData();
        formData.append("requestObjectId", id);

        formData.append("objectKey", headerKey);
        formData.append("value", headerValue);

        // console.log("Before to")
        // console.log(formData)
        const formDataObject = {};
        formData.forEach((value, key) => {
            formDataObject[key] = value;
        });
        // console.log("Updating to....")
        // console.log(formDataObject)
        // console.log(token)
        postItem(`/requestObject/customHeader`, formDataObject, token).then(resp => {
            setShowSetHeader(false)
            setIsLoaded(true)
            let state = "success"
            history.push("/request-object", state);

        }).catch(resp => {
            setIsLoaded(true)
            let state = "danger"

            history.push("/request-object", state);
        })
    }

    const saveBodyObject = () => {
        // console.log(requestobject)
        const formData = new FormData();
        formData.append("requestObjectId", id);
        formData.append("objectType", bodyType);
        formData.append("objectKey", bodyKey);
        formData.append("value", bodyValue);

        // console.log("Before to")
        // console.log(formData)
        const formDataObject = {};
        formData.forEach((value, key) => {
            formDataObject[key] = value;
        });
        // console.log("Updating to....")
        // console.log(formDataObject)
        // console.log(token)
        postItem(`/requestObject/customBody`, formDataObject, token).then(resp => {
            setShowSetBody(false)
            setIsLoaded(true)
            let state = "success"
            history.push("/request-object", state);

        }).catch(resp => {
            setIsLoaded(true)
            let state = "danger"

            history.push("/request-object", state);
        })
    }

    const updateBody = () => {
        const formData = new FormData();
        formData.append("requestObjectId", id);
        formData.append("objectType", bodyUpdateType);
        formData.append("objectKey", bodyUpdateKey);
        formData.append("id", bodyUpdateId);

        const formDataObject = {};
        formData.forEach((value, key) => {
            formDataObject[key] = value;
        });
        postItem(`/requestObject/update/bodyItem`, formDataObject, token).then(resp => {
            setShowUpdateBody(false)
            setIsLoaded(true)
            let state = "success"

            history.push("/request-object", state);

        }).catch(resp => {
            setIsLoaded(true)
            let state = "danger"

            history.push("/request-object", state);
        })
    }

    const updateHeader = () => {
        const formData = new FormData();
        formData.append("requestObjectId", id);
        formData.append("objectType", headerUpdateType);
        formData.append("objectKey", headerUpdateKey);
        formData.append("id", headerUpdateId);

        const formDataObject = {};
        formData.forEach((value, key) => {
            formDataObject[key] = value;
        });
        postItem(`/requestObject/update/headerItem`, formDataObject, token).then(resp => {
            setShowUpdateHeader(false)
            setIsLoaded(true)
            let state = "success"

            history.push("/request-object", state);

        }).catch(resp => {
            setIsLoaded(true)
            let state = "danger"

            history.push("/request-object", state);
        })
    }

    const updateResponseBody = () => {
        const formData = new FormData();
        formData.append("requestObjectId", id);
        formData.append("objectType", responseBodyUpdateType);
        formData.append("objectKey", responseBodyUpdateKey);
        formData.append("id", responseBodyUpdateId);

        const formDataObject = {};
        formData.forEach((value, key) => {
            formDataObject[key] = value;
        });
        postItem(`/requestObject/update/reponseBodyItem`, formDataObject, token).then(resp => {
            setShowUpdateResponseBody(false)
            setIsLoaded(true)
            let state = "success"

            history.push("/request-object", state);

        }).catch(resp => {
            setIsLoaded(true)
            let state = "danger"

            history.push("/request-object", state);
        })
    }
    const deleteRequestObject = () => {
        deleteItem(`/requestObject/${id}`, token).then(resp => {
            let state = "success"
            // notify("tl", message, "success")

            history.push("/request-objects", state);

        }).catch(resp => {
            setIsLoaded(true)
            let state = "danger"
            // notify("tl", message, "success")

            history.push("/request-objects", state);
        })
    }

    return (
        <>
            <NotificationAlert ref={notificationAlertRef} />
            {IsLoaded ?
                <div className="content">
                    <Row>
                        <Col>
                            <Card className="card-plain">
                                <CardHeader><Row><Col><h2 className="title">{requestobject.label}</h2></Col>

                                    <Col><div className="button-container">
                                        {requestobject.authType && requestobject.authType !== "No Auth" && (
                                            <>
                                                <Button className="btn-icon btn-round" onClick={toggleShowUpdateAuth}>
                                                    <i className="tim-icons icon-key-25" style={{ color: "rgba(35, 46, 222, 1" }} />
                                                </Button>&nbsp;&nbsp;&nbsp;
                                            </>
                                        )

                                        }
                                        <Button className="btn-icon btn-round" onClick={toggleShowUpdate}>
                                            <i className="tim-icons icon-pencil" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                        </Button>&nbsp;&nbsp;&nbsp;
                                        <Button className="btn-icon btn-round" onClick={toggleShowAdd}>
                                            <i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                        </Button>&nbsp;&nbsp;&nbsp;
                                        <Button className="btn-icon btn-round" onClick={deleteRequestObject}>
                                            <i className="tim-icons icon-trash-simple" style={{ color: "red" }} />
                                        </Button>
                                    </div></Col></Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardBody>
                                                            <p className="description" style={{ marginBottom: "15px", marginLeft: "5px" }}>ID: {requestobject.requestObjectId}</p>
                                                            <p className="description" style={{ marginBottom: "15px", marginLeft: "5px" }}>Provider: {requestobject.provider}</p>
                                                            <p className="description" style={{ marginBottom: "15px", marginLeft: "5px" }}>Endpoint: {requestobject.endpoint}</p>

                                                            <p className="description" style={{ marginBottom: "15px", marginLeft: "5px" }}>Base URL: {requestobject.url}</p>

                                                            {hasFallback ? <Button style={{ color: `${textColour}` }}
                                                                onClick={() => {
                                                                    setRequestObject(fallback);
                                                                    setFallback([]);
                                                                    setHasFallback(false);
                                                                }}
                                                            >{fallback.endpoint}</Button>
                                                                : <></>}
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card>
                                                        <CardBody>
                                                            <p className="description" style={{ marginBottom: "15px", marginLeft: "5px" }}>Request Type: {requestobject.requestType}</p>
                                                            <p className="description" style={{ marginBottom: "15px", marginLeft: "5px" }}>Description: {requestobject.description}</p>

                                                            {(requestobject.pathVariable) != undefined && (requestobject.pathVariable) != null && (requestobject.pathVariable) != '' && (requestobject.pathVariable) != 'null'
                                                                ? <span><p className="description" style={{ marginBottom: "15px", marginLeft: "5px" }}>Path Variable: {requestobject.pathVariable}</p>
                                                                    <Button className="btn-icon btn-round" onClick={() => toggleShowSetBody(requestobject.pathVariable, "pathVariable")}>
                                                                        <i className="tim-icons icon-key-25" style={{ color: "rgba(35, 46, 222, 1" }} />
                                                                    </Button>&nbsp;&nbsp;&nbsp;</span>
                                                                : <></>}
                                                            {hasAuthRequestObject ? <Button style={{ color: `${textColour}` }}
                                                                onClick={() => {
                                                                    setRequestObject(authRequestObject);
                                                                    setAuthRequestObject([]);
                                                                    setHasAuthRequestObject(false);
                                                                }}
                                                            >{authRequestObject.endpoint}</Button>
                                                                : <></>}
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>

                                            {showUpdateAuth ? <Row><Card>
                                                <CardHeader>
                                                    <h5 className="title">Update Auth Info</h5>
                                                </CardHeader>
                                                <CardBody>
                                                    <Form>
                                                        {requestobject.authType.toLowerCase() === 'basic auth' ? <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Username</label>
                                                                    <Input
                                                                        placeholder="Username"
                                                                        type="text"
                                                                        onChange={(e) => setUsername(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            {/* </Row>
                                                        <Row> */}
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Password</label>
                                                                    <Input
                                                                        placeholder="Password"
                                                                        type="text"
                                                                        onChange={(e) => setPassword(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row> : <></>}
                                                        {requestobject.authType.toLowerCase() === 'bearer auth' ? <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Token</label>
                                                                    <Input
                                                                        placeholder="Token"
                                                                        type="text"
                                                                        onChange={(e) => setAuthToken(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            {/* </Row>
                                                        <Row> */}
                                                        </Row> : <></>}
                                                        {requestobject.authType.toLowerCase() === 'api key' ? <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Key</label>
                                                                    <Input
                                                                        placeholder="Key"
                                                                        type="text"
                                                                        onChange={(e) => setKey(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            {/* </Row>
                                                        <Row> */}
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Value</label>
                                                                    <Input
                                                                        placeholder="Value"
                                                                        type="text"
                                                                        onChange={(e) => setValue(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row> : <></>}
                                                        {requestobject.authType.toLowerCase() === 'jwt token' || requestobject.authType.toLowerCase() === 'oauth2' ? <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Token</label>
                                                                    <Input
                                                                        placeholder="Token"
                                                                        type="text"
                                                                        onChange={(e) => setAuthToken(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            {/* </Row>
                                                        <Row> */}
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Request Header Prefix</label>
                                                                    <Input
                                                                        placeholder="request header prefix"
                                                                        type="text"
                                                                        onChange={(e) => setRequestHeaderPrefix(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row> : <></>}
                                                    </Form>
                                                </CardBody>
                                                <CardFooter>
                                                    <Button className="btn-fill" color="primary" type="submit" onClick={saveAuthObject}>
                                                        Save
                                                    </Button>
                                                </CardFooter>
                                            </Card></Row> : <></>}

                                            {showSetHeader ? <Row><Card>
                                                <CardHeader>
                                                    <h5 className="title">Update {headerKey}</h5>
                                                </CardHeader>
                                                <CardBody>
                                                    <Form>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Value</label>
                                                                    <Input
                                                                        placeholder="Value"
                                                                        type="text"
                                                                        onChange={(e) => setHeaderValue(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </CardBody>
                                                <CardFooter>
                                                    <Button className="btn-fill" color="primary" type="submit" onClick={saveHeaderObject}>
                                                        Save
                                                    </Button>
                                                </CardFooter>
                                            </Card></Row> : <></>}

                                            {showSetBody ? <Row><Card>
                                                <CardHeader>
                                                    <h5 className="title">Update {bodyKey}</h5>
                                                </CardHeader>
                                                <CardBody>
                                                    <Form>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Value</label>
                                                                    <Input
                                                                        placeholder="Value"
                                                                        type="text"
                                                                        onChange={(e) => setBodyValue(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </CardBody>
                                                <CardFooter>
                                                    <Button className="btn-fill" color="primary" type="submit" onClick={saveBodyObject}>
                                                        Save
                                                    </Button>
                                                </CardFooter>
                                            </Card></Row> : <></>}

                                            {showUpdateBody ? <Row><Card>
                                                <CardHeader>
                                                    <h5 className="title">Update {bodyUpdateId}</h5>
                                                </CardHeader>
                                                <CardBody>
                                                    <Form>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Var Name</label>
                                                                    <Input
                                                                        value={bodyUpdateKey}
                                                                        placeholder="name"
                                                                        type="text"
                                                                        onChange={(e) => setBodyUpdateKey(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Var Type</label>
                                                                    <Input
                                                                        value={bodyUpdateType}
                                                                        placeholder="string"
                                                                        type="text"
                                                                        onChange={(e) => setBodyUpdateType(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </CardBody>
                                                <CardFooter>
                                                    <Button className="btn-fill" color="primary" type="submit" onClick={updateBody}>
                                                        Save
                                                    </Button>
                                                </CardFooter>
                                            </Card></Row> : <></>}

                                            {showUpdateHeader ? <Row><Card>
                                                <CardHeader>
                                                    <h5 className="title">Update {headerUpdateId}</h5>
                                                </CardHeader>
                                                <CardBody>
                                                    <Form>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Var Name</label>
                                                                    <Input
                                                                        value={headerUpdateKey}
                                                                        placeholder="name"
                                                                        type="text"
                                                                        onChange={(e) => setHeaderUpdateKey(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Var Type</label>
                                                                    <Input
                                                                        value={headerUpdateType}
                                                                        placeholder="string"
                                                                        type="text"
                                                                        onChange={(e) => setHeaderUpdateType(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </CardBody>
                                                <CardFooter>
                                                    <Button className="btn-fill" color="primary" type="submit" onClick={updateHeader}>
                                                        Save
                                                    </Button>
                                                </CardFooter>
                                            </Card></Row> : <></>}

                                            {showUpdateResponseBody ? <Row><Card>
                                                <CardHeader>
                                                    <h5 className="title">Update {responseBodyUpdateId}</h5>
                                                </CardHeader>
                                                <CardBody>
                                                    <Form>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Var Name</label>
                                                                    <Input
                                                                        value={responseBodyUpdateKey}
                                                                        placeholder="name"
                                                                        type="text"
                                                                        onChange={(e) => setResponseBodyUpdateKey(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Var Type</label>
                                                                    <Input
                                                                        value={responseBodyUpdateType}
                                                                        placeholder="string"
                                                                        type="text"
                                                                        onChange={(e) => setResponseBodyUpdateType(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </CardBody>
                                                <CardFooter>
                                                    <Button className="btn-fill" color="primary" type="submit" onClick={updateResponseBody}>
                                                        Save
                                                    </Button>
                                                </CardFooter>
                                            </Card></Row> : <></>}

                                            <Row>
                                                {requestobject.params != null && requestobject.params != 'null' && requestobject.params.length > 0 ? <Col>
                                                    <Card className='card-plain'>
                                                        <CardHeader><h4>Params</h4></CardHeader>
                                                        {/* <CardBody> */}
                                                        <TableContainer sx={{ maxHeight: 700 }}>
                                                            <Table stickyHeader size="small" aria-label="sticky table" id="table-client">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Key</TableCell>
                                                                        <TableCell>Action</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {(requestobject.params)
                                                                        .map((item) => {
                                                                            return (
                                                                                <TableRow hover tabIndex={-1} key={item}>
                                                                                    <TableCell style={{ color: `${textColour}` }}>{item}</TableCell>
                                                                                    <TableCell style={{ color: `${textColour}` }}>
                                                                                        <Button className="btn-icon btn-round" onClick={() => toggleShowSetBody(item, "param")}>
                                                                                            <i className="tim-icons icon-key-25" style={{ color: "rgba(35, 46, 222, 1" }} />
                                                                                        </Button>&nbsp;&nbsp;&nbsp;
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            );
                                                                        })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        {/* {requestobject.headers.map((header) => {
                                                                return (
                                                                    <div className="description" style={{ marginTop: "5px", marginLeft: "5px" }} key={header.keyName}>{header.keyName}: {header.valueDataType}</div>
                                                                );
                                                            })} */}


                                                        {/* </CardBody> */}
                                                    </Card>
                                                </Col> : <></>}
                                                {requestobject.headers != null && requestobject.headers != undefined && requestobject.headers.length > 0 ? <Col>
                                                    <Card className='card-plain'>
                                                        <CardHeader><h4>Headers</h4></CardHeader>
                                                        {/* <CardBody> */}
                                                        <TableContainer sx={{ maxHeight: 700 }}>
                                                            <Table stickyHeader size="small" aria-label="sticky table" id="table-client">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>ID</TableCell>
                                                                        <TableCell>Key Name</TableCell>
                                                                        <TableCell>Value Type</TableCell>
                                                                        <TableCell>Action</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {(requestobject.headers)
                                                                        .map((item) => {
                                                                            return (
                                                                                <TableRow hover tabIndex={-1} key={item.id}>
                                                                                    <TableCell style={{ color: `${textColour}` }}>{item.id}</TableCell>
                                                                                    <TableCell style={{ color: `${textColour}` }}>{item.keyName}</TableCell>
                                                                                    <TableCell style={{ color: `${textColour}` }}>{item.valueDataType}</TableCell>
                                                                                    <TableCell style={{ color: `${textColour}` }}>
                                                                                        <Button className="btn-icon btn-round" onClick={() => toggleShowUpdateHeader(item)}>
                                                                                            <i className="tim-icons icon-pencil" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                                                                        </Button>&nbsp;&nbsp;&nbsp;
                                                                                        {item.keyName === 'Authorization' ? <></> : <Button className="btn-icon btn-round" onClick={() => toggleShowSetHeader(item.keyName)}>
                                                                                            <i className="tim-icons icon-key-25" style={{ color: "rgba(35, 46, 222, 1" }} />
                                                                                        </Button>}&nbsp;&nbsp;&nbsp;
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            );
                                                                        })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        {/* {requestobject.headers.map((header) => {
                                                                return (
                                                                    <div className="description" style={{ marginTop: "5px", marginLeft: "5px" }} key={header.keyName}>{header.keyName}: {header.valueDataType}</div>
                                                                );
                                                            })} */}


                                                        {/* </CardBody> */}
                                                    </Card>
                                                </Col> : <></>}
                                                {requestobject.body != null && requestobject.body != undefined && requestobject.body.length > 0 ? <Col>
                                                    <Card className='card-plain'>
                                                        <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: "transparent" }}>
                                                            <CardHeader><h4>Body</h4></CardHeader>
                                                            <TableContainer sx={{ maxHeight: 700 }}>
                                                                <Table stickyHeader size="small" aria-label="sticky table" id="table-client">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>ID</TableCell>
                                                                            <TableCell>Key Name</TableCell>
                                                                            <TableCell>Value Type</TableCell>
                                                                            <TableCell>Action</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {(requestobject.body)
                                                                            .map((item) => {
                                                                                return (
                                                                                    <TableRow hover tabIndex={-1} key={item.id}>
                                                                                        <TableCell style={{ color: `${textColour}` }}>{item.id}</TableCell>
                                                                                        <TableCell style={{ color: `${textColour}` }}>{item.keyName}</TableCell>
                                                                                        <TableCell style={{ color: `${textColour}` }}>{item.valueDataType}</TableCell>
                                                                                        <TableCell style={{ color: `${textColour}` }}>
                                                                                            <Button className="btn-icon btn-round" onClick={() => toggleShowUpdateBody(item)}>
                                                                                                <i className="tim-icons icon-pencil" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                                                                            </Button>&nbsp;&nbsp;&nbsp;
                                                                                            <Button className="btn-icon btn-round" onClick={() => toggleShowSetBody(item.keyName, "body")}>
                                                                                                <i className="tim-icons icon-key-25" style={{ color: "rgba(35, 46, 222, 1" }} />
                                                                                            </Button>&nbsp;&nbsp;&nbsp;
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Paper>
                                                    </Card>
                                                </Col> : <></>}
                                                {requestobject.responseBody != null && requestobject.responseBody != undefined && requestobject.responseBody.length > 0 ? <Col>
                                                    <Card className='card-plain'>
                                                        <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: "transparent" }}>
                                                            <CardHeader><h4>ResponseBody</h4></CardHeader>
                                                            {(requestobject.responseBody).split("\n")
                                                                            .map((item) => {
                                                                                return (
                                                                                    <p>{item}</p>
                                                                                    );
                                                                                })}
                                                            {/* <TableContainer sx={{ maxHeight: 700 }}>
                                                                <Table stickyHeader size="small" aria-label="sticky table" id="table-client">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>ID</TableCell>
                                                                            <TableCell>Key Name</TableCell>
                                                                            <TableCell>Value Type</TableCell>
                                                                            <TableCell>Action</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {(requestobject.responseBody)
                                                                            .map((item) => {
                                                                                return (
                                                                                    <TableRow hover tabIndex={-1} key={item.id}>
                                                                                        <TableCell style={{ color: `${textColour}` }}>{item.id}</TableCell>
                                                                                        <TableCell style={{ color: `${textColour}` }}>{item.keyName}</TableCell>
                                                                                        <TableCell style={{ color: `${textColour}` }}>{item.valueDataType}</TableCell>
                                                                                        <TableCell style={{ color: `${textColour}` }}>
                                                                                            <Button className="btn-icon btn-round" onClick={() => toggleShowUpdateResponseBody(item)}>
                                                                                                <i className="tim-icons icon-pencil" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                                                                            </Button>&nbsp;&nbsp;&nbsp;</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer> */}
                                                        </Paper>
                                                    </Card>
                                                </Col> : <></>}
                                            </Row>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                            {showUpdate ? <Card>
                                <RequestObjectLimitedManager value={requestobject} />
                            </Card> : <></>}

                            {showAdd ? <Card>
                                <RequestObjectDataManager value={requestobject} />
                            </Card> : <></>}
                        </Col>

                    </Row>
                </div>
                : <></>}
        </>
    );
}

export default RequestObject;