import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import NonAdminLayout from "layouts/NonAdmin/NonAdmin.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";

import { Blocks } from 'react-loader-spinner'
import '@aws-amplify/ui-react/styles.css';
import "assets/css/auth.css"
import { KeycloakContext } from "contexts/KeyCloakProvider";


export default function App() {
    const [isPresentResult, setIsPresentResult] = useState(false);
    const [IsLoaded, setIsLoaded] = useState(false);
    
    const context = useContext(KeycloakContext);
    const { initialized, keycloak } = context
    
    useEffect(() => {
      const roles = JSON.parse(process.env.REACT_APP_KEYCLOAK_ROLES || '[]'); 
      console.log("Roles",roles)
      const checkKeycloakInitialization = () => {
        if (keycloak.authenticated && keycloak.token && keycloak.tokenParsed) {
          // Keycloak is initialized, and you can now check roles
          const isSuperAdmin = roles.some(r => keycloak.hasRealmRole(r));
          setIsPresentResult(isSuperAdmin);
          setIsLoaded(true);
        } else {
          keycloak.login()
        }
      };
  
      if (initialized) {
        // Keycloak has been initialized
        checkKeycloakInitialization();
      }
    }, [initialized, keycloak]);
  
    return ( <>
    
      {IsLoaded ? (
        <>
            {isPresentResult ? (
              <>
                <ThemeContextWrapper>
                  <BackgroundColorWrapper>
                    <BrowserRouter>
                      <Switch>
                        <Route path="/" render={(props) => <AdminLayout {...props} />} />
                      </Switch>
                    </BrowserRouter>
                  </BackgroundColorWrapper>
                </ThemeContextWrapper>
              </>)
              : (
                <ThemeContextWrapper>
                  <BackgroundColorWrapper>
                    <BrowserRouter>
                      <Switch>
                        <Route path="/" render={(props) => <NonAdminLayout {...props} />} />
                      </Switch>
                    </BrowserRouter>
                  </BackgroundColorWrapper>
                </ThemeContextWrapper>
              )
            }
          
        </>)
        : (
          <div style={{display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh'}}>
          <Blocks
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
            /></div>
  
        )
      }
      </>
      
    );
  }