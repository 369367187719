/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* RequestObject Page: https://www.creative-tim.com/requestobject/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useContext } from 'react';
import { getItem, postFileItem, postItem, updateFileItem, updateItem } from "../services/api.svs";
import { useHistory } from "react-router-dom";

import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  FormText,
} from "reactstrap";
import { Auth } from 'aws-amplify';
import { Blocks } from 'react-loader-spinner';
import { Checkbox, FormControlLabel } from '@mui/material';
import { KeycloakContext } from 'contexts/KeyCloakProvider';

function RequestObjectDataManager(props) {

  const context = useContext(KeycloakContext);
  const { keycloak } = context
  const token = keycloak.token

  const [filledRequestObject, setFilledRequestObject] = useState(props.value)

  const [IsLoaded, setIsLoaded] = useState(false);
  const requestTypes = ['GET', 'POST', 'UPDATE', 'DELETE', 'REQUEST']

  // request object data
  const [requestObjectId, setId] = useState(filledRequestObject.requestObjectId);

  const [header, setHeader] = useState([]);
  const [params, setParams] = useState([]);
  const [body, setBody] = useState([]);
  // const [responseBody, setResponseBody] = useState([]);
  const [code, setCode] = useState([]);

  const [showUpdateHeader, setShowUpdateHeader] = useState(false);
  const [showUpdateParams, setShowUpdateParams] = useState(false);
  const [showUpdateBody, setShowUpdateBody] = useState(false);
  // const [showUpdateResponseBody, setShowUpdateResponseBody] = useState(false);
  const [showUpdateCode, setShowUpdateCode] = useState(false);

  const [headerType, setHeaderType] = useState('');
  const [bodyType, setBodyType] = useState('');
  // const [responseBodyType, setResponseBodyType] = useState('');

  const [headerDataSet, setHeaderDataSet] = useState({ keyName: '', valueDataType: '', required: false });
  const [bodyDataSet, setBodyDataSet] = useState({ keyName: '', valueDataType: '', required: false });
  // const [responseBodyDataSet, setResponseBodyDataSet] = useState({ keyName: '', valueDataType: '', required: false });
  const [codeDataSet, setCodeDataSet] = useState({ language: '', codeSnippet: '' });

  const [headerData, setHeaderData] = useState([]);
  const [currentParam, setCurrentParam] = useState('')
  const [bodyData, setBodyData] = useState([]);
  // const [responseBodyData, setResponseBodyData] = useState([]);
  const [codeData, setCodeData] = useState([]);

  const [headerDataCount, setHeaderDataCount] = useState([]);
  const [paramsCount, setParamsCount] = useState([]);
  const [bodyDataCount, setBodyDataCount] = useState([]);
  // const [responseBodyDataCount, setResponseBodyDataCount] = useState([]);
  const [codeDataCount, setCodeDataCount] = useState([]);

  const notificationAlertRef = React.useRef(null);
  const history = useHistory();


  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const submitHeader = () => {
    const formData = new FormData();
    formData.append("requestObjectId", requestObjectId)
    const data = Object.fromEntries(formData.entries());
    data.header = { type: headerType, data: headerData };
    // data.body = { type: bodyType, data: bodyData };
    // data.responseBody = { type: responseBodyType, data: responseBodyData };
    // data.code = codeData;
    // data.params = params;
    updateItem(`/requestObject/header`, data, token).then(resp => {
      console.log('updating')
      setIsLoaded(true)
      let state = "success"

      history.push("/request-objects", state);
    }).catch(resp => {
      setIsLoaded(true)
      let state = "danger"

      history.push("/request-objects", state);
    })

  }

  const submitBody = () => {
    const formData = new FormData();
    formData.append("requestObjectId", requestObjectId)
    const data = Object.fromEntries(formData.entries());
    // data.header = { type: headerType, data: headerData };
    data.body = { type: bodyType, data: bodyData };
    // data.responseBody = { type: responseBodyType, data: responseBodyData };
    // data.code = codeData;
    // data.params = params;
    updateItem(`/requestObject/body`, data, token).then(resp => {
      console.log('updating')
      setIsLoaded(true)
      let state = "success"

      history.push("/request-objects", state);
    }).catch(resp => {
      setIsLoaded(true)
      let state = "danger"

      history.push("/request-objects", state);
    })

  }

  // const submitResponseBody = () => {
  //   const formData = new FormData();
  //   formData.append("requestObjectId", requestObjectId)
  //   const data = Object.fromEntries(formData.entries());
  //   // data.header = { type: headerType, data: headerData };
  //   // data.body = { type: bodyType, data: bodyData };
  //   data.responseBody = { type: responseBodyType, data: responseBodyData };
  //   // data.code = codeData;
  //   // data.params = params;
  //   updateItem(`/requestObject/responseBody`, data, token).then(resp => {
  //     console.log('updating')
  //     setIsLoaded(true)
  //     let state = "success"

  //     history.push("/request-objects", state);
  //   }).catch(resp => {
  //     setIsLoaded(true)
  //     let state = "danger"

  //     history.push("/request-objects", state);
  //   })

  // }

  const submitParams = () => {
    const formData = new FormData();
    formData.append("requestObjectId", requestObjectId)
    const data = Object.fromEntries(formData.entries());
    // data.header = { type: headerType, data: headerData };
    // data.body = { type: bodyType, data: bodyData };
    // data.responseBody = { type: responseBodyType, data: responseBodyData };
    // data.code = codeData;
    data.params = params;
    updateItem(`/requestObject/params`, data, token).then(resp => {
      console.log('updating')
      setIsLoaded(true)
      let state = "success"

      history.push("/request-objects", state);
    }).catch(resp => {
      setIsLoaded(true)
      let state = "danger"

      history.push("/request-objects", state);
    })

  }

  const submitCode = () => {
    const formData = new FormData();
    formData.append("requestObjectId", requestObjectId)
    const data = Object.fromEntries(formData.entries());
    // data.header = { type: headerType, data: headerData };
    // data.body = { type: bodyType, data: bodyData };
    // data.responseBody = { type: responseBodyType, data: responseBodyData };
    data.code = codeData;
    // data.params = params;
    updateItem(`/requestObject/code`, data, token).then(resp => {
      console.log('updating')
      setIsLoaded(true)
      let state = "success"

      history.push("/request-objects", state);
    }).catch(resp => {
      setIsLoaded(true)
      let state = "danger"

      history.push("/request-objects", state);
    })

  }

  // headers
  const toggleAddHeader = () => {
    setShowUpdateHeader(showUpdateHeader ? false : true)
  }

  const addHeaderPairInput = () => {
    setHeaderDataCount(prevCount => [...prevCount, prevCount.length + 1])
  }

  const removeHeaderPairInput = () => {
    if (headerDataCount.length === 1) {
      setHeaderDataCount([])
    } else {
      setHeaderDataCount(prevCount => [...prevCount.slice(0, -2), prevCount.length - 1])
    }
  }

  const setCurrentHeaderKey = (e) => {
    setHeaderDataSet({ ...headerDataSet, keyName: e.target.value })
  }

  const setCurrentHeaderValueType = (e) => {
    setHeaderDataSet({ ...headerDataSet, valueDataType: e.target.value })

  }

  const setCurrentHeaderRequired = (e) => {
    setHeaderDataSet({ ...headerDataSet, required: e.target.checked })
  }

  const setHeaderInfo = () => {
    setHeader({ type: headerType, data: headerData })
  }

  const addHeaderPair = () => {
    headerData.push(headerDataSet);
  }

  // params
  const toggleAddParams = () => {
    setShowUpdateParams(showUpdateParams ? false : true)
  }

  const addParamInput = () => {
    setParamsCount(prevCount => [...prevCount, prevCount.length + 1])
  }

  const removeParamInput = () => {
    if (paramsCount.length === 1) {
      setParamsCount([])
    } else {
      setParamsCount(prevCount => [...prevCount.slice(0, -2), prevCount.length - 1])
    }
  }

  const addParam = () => {
    params.push(currentParam);
  }

  // body
  const toggleAddBody = () => {
    setShowUpdateBody(showUpdateBody ? false : true)
  }

  const addBodyPairInput = () => {
    setBodyDataCount(prevCount => [...prevCount, prevCount.length + 1])
  }

  const removeBodyPairInput = () => {
    if (bodyDataCount.length === 1) {
      setBodyDataCount([])
    } else {

      setBodyDataCount(prevCount => [...prevCount.slice(0, -2), prevCount.length - 1])
    }
  }

  const setCurrentBodyKey = (e) => {
    setBodyDataSet({ ...bodyDataSet, keyName: e.target.value })
  }

  const setCurrentBodyValueType = (e) => {
    setBodyDataSet({ ...bodyDataSet, valueDataType: e.target.value })

  }

  const setCurrentBodyRequired = (e) => {
    setBodyDataSet({ ...bodyDataSet, required: e.target.checked })
  }

  const setBodyInfo = () => {
    setBody({ type: bodyType, data: bodyData })
  }

  const addBodyPair = () => {
    bodyData.push(bodyDataSet);
  }

  // // responseBody
  // const toggleAddResponseBody = () => {
  //   setShowUpdateResponseBody(showUpdateResponseBody ? false : true)
  // }

  // const addResponseBodyPairInput = () => {
  //   setResponseBodyDataCount(prevCount => [...prevCount, prevCount.length + 1])
  // }

  // const removeResponseBodyPairInput = () => {
  //   if (responseBodyDataCount.length === 1) {
  //     setResponseBodyDataCount([])
  //   } else {
  //     setResponseBodyDataCount(prevCount => [...prevCount.slice(0, -2), prevCount.length - 1])
  //   }
  // }

  // const setCurrentResponseBodyKey = (e) => {
  //   setResponseBodyDataSet({ ...responseBodyDataSet, keyName: e.target.value })
  // }

  // const setCurrentResponseBodyValueType = (e) => {
  //   setResponseBodyDataSet({ ...responseBodyDataSet, valueDataType: e.target.value })
  // }

  // const setCurrentResponseBodyRequired = (e) => {
  //   setResponseBodyDataSet({ ...responseBodyDataSet, required: e.target.checked })
  // }

  // const setResponseBodyInfo = () => {
  //   setResponseBody({ type: responseBodyType, data: responseBodyData })
  // }

  // const addResponseBodyPair = () => {
  //   responseBodyData.push(responseBodyDataSet);
  // }

  // codeData
  const toggleAddCode = () => {
    setShowUpdateCode(showUpdateCode ? false : true)
  }

  const addCodeDataPairInput = () => {
    setCodeDataCount(prevCount => [...prevCount, prevCount.length + 1])
  }

  const removeCodeDataPairInput = () => {
    if (codeDataCount.length === 1) {
      setCodeDataCount([])
    } else {
      setCodeDataCount(prevCount => [...prevCount.slice(0, -2), prevCount.length - 1])
    }
  }

  const setCurrentCodeLanguage = (e) => {
    setCodeDataSet({ ...codeDataSet, language: e.target.value })
  }

  const setCurrentCodeSnippet = (e) => {
    setCodeDataSet({ ...codeDataSet, codeSnippet: e.target.value })
  }

  const addCodeDataPair = () => {
    codeData.push(codeDataSet);
  }

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
        <div className="content">
          <Row>
            <Col>
              <Button onClick={toggleAddHeader} style={{marginLeft: "4em"}}>Add Headers</Button>
            </Col>
            <Col>
              <Button onClick={toggleAddBody}>Add Body</Button>
            </Col>
            {/* <Col>
              <Button onClick={toggleAddResponseBody}>Add ResponseBody</Button>
            </Col> */}
            <Col>
              <Button onClick={toggleAddParams}>Add Params</Button>
            </Col>
            <Col>
              <Button onClick={toggleAddCode}>Add Code Snippets</Button>
            </Col>
          </Row>

          {showUpdateHeader ?
            <Card>
              <CardHeader><h5 className="title">Headers</h5></CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>Type</label>
                        <Input
                          id="exampleText"
                          placeholder="application/json"
                          type="text"
                          onChange={(e) => setHeaderType(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {headerDataCount
                    .map((index) => {
                      return (
                        <div>
                          <Row>
                            <Col>
                              <label>Header Key</label></Col>
                            <Col>
                              <label>Header Value Type</label></Col>
                            <Col></Col>
                          </Row>
                          <Row key={index} width="100%">
                            <Col>
                              <FormGroup>
                                <Input
                                  id="key"
                                  placeholder="Authorization"
                                  type="text"
                                  onChange={(e) => setCurrentHeaderKey(e, index)}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Input
                                  id="valueType"
                                  placeholder="Basic Auth"
                                  type="text"
                                  onChange={(e) => setCurrentHeaderValueType(e, index)}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox onChange={setCurrentHeaderRequired} />
                                  }
                                  label="Required"
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <Button onClick={addHeaderPair}><i className="tim-icons icon-check-2" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                              <Button onClick={removeHeaderPairInput}><i className="tim-icons icon-simple-remove" style={{ color: "rgba(222, 35, 35, 1)" }} /></Button>
                            </Col>
                          </Row>
                        </div>
                      )
                    })}
                  <Row>
                    <Col>
                      <Button onClick={addHeaderPairInput}><i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                    </Col>
                  </Row>
                </Form>

              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="submit" onClick={submitHeader}>
                  Save
                </Button>
              </CardFooter>
            </Card> : <></>}

          {/* params */}
          {showUpdateParams ?
            <Card>
              <CardHeader><h5 className="title">Params</h5></CardHeader>
              <CardBody>
                <Form>
                  {paramsCount
                    .map((index) => {
                      return (
                        <div>
                          <Row>
                            <Col>
                              <label>Param Name</label></Col>
                            <Col></Col>
                          </Row>
                          <Row key={index} width="100%">
                            <Col>
                              <FormGroup>
                                <Input
                                  id="key"
                                  placeholder="SessionId"
                                  type="text"
                                  onChange={(e) => setCurrentParam(e.target.value)}
                                />
                              </FormGroup>
                            </Col>

                            <Col>
                              <Button onClick={addParam}><i className="tim-icons icon-check-2" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                              <Button onClick={removeParamInput}><i className="tim-icons icon-simple-remove" style={{ color: "rgba(222, 35, 35, 1)" }} /></Button>
                            </Col>
                          </Row>
                        </div>
                      )
                    })}
                  <Row>
                    <Col>
                      <Button onClick={addParamInput}><i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                    </Col>
                  </Row>
                </Form>

              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="submit" onClick={submitParams}>
                  Save
                </Button>
              </CardFooter>
            </Card> : <></>}


          {/* body */}
          {showUpdateBody ?
            <Card>
              <CardHeader><h5 className="title">Body</h5></CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col width="50%">
                      <FormGroup>
                        <label>Type</label>
                        <Input
                          id="exampleText"
                          placeholder="application/json"
                          type="text"
                          onChange={(e) => setBodyType(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {bodyDataCount
                    .map((index) => {
                      return (
                        <div>
                          <Row>
                            <Col>
                              <label>Body Key</label></Col>
                            <Col>
                              <label>Body Value Type</label></Col>
                            <Col></Col>
                          </Row>
                          <Row key={index} width="100%">
                            <Col>
                              <FormGroup>
                                <Input
                                  id="key"
                                  placeholder="Name"
                                  type="text"
                                  onChange={(e) => setCurrentBodyKey(e, index)}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Input
                                  id="valueType"
                                  placeholder="String"
                                  type="text"
                                  onChange={(e) => setCurrentBodyValueType(e, index)}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox onChange={setCurrentBodyRequired} />
                                  }
                                  label="Required"
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <Button onClick={addBodyPair}><i className="tim-icons icon-check-2" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                              <Button onClick={removeBodyPairInput}><i className="tim-icons icon-simple-remove" style={{ color: "rgba(222, 35, 35, 1)" }} /></Button>
                            </Col>
                          </Row>
                        </div>
                      )
                    })}
                  <Row>
                    <Col>
                      <Button onClick={addBodyPairInput}><i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                    </Col>
                  </Row>
                </Form>

              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="submit" onClick={submitBody}>
                  Save
                </Button>
              </CardFooter>
            </Card> : <></>}

          {/* responseBody
          {showUpdateResponseBody ?
            <Card>
              <CardHeader><h5 className="title">Response Body</h5></CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col width="50%">
                      <FormGroup>
                        <label>Type</label>
                        <Input
                          id="exampleText"
                          placeholder="application/json"
                          type="text"
                          onChange={(e) => setResponseBodyType(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {responseBodyDataCount
                    .map((index) => {
                      return (
                        <div>
                          <Row>
                            <Col>
                              <label>Response Body Key</label></Col>
                            <Col>
                              <label>Response Body Value Type</label></Col>
                            <Col></Col>
                          </Row>
                          <Row key={index} width="100%">
                            <Col>
                              <FormGroup>
                                <Input
                                  id="key"
                                  placeholder="Name"
                                  type="text"
                                  onChange={(e) => setCurrentResponseBodyKey(e, index)}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Input
                                  id="valueType"
                                  placeholder="String"
                                  type="text"
                                  onChange={(e) => setCurrentResponseBodyValueType(e, index)}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox onChange={setCurrentResponseBodyRequired} />
                                  }
                                  label="Required"
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <Button onClick={addResponseBodyPair}><i className="tim-icons icon-check-2" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                              <Button onClick={removeResponseBodyPairInput}><i className="tim-icons icon-simple-remove" style={{ color: "rgba(222, 35, 35, 1)" }} /></Button>
                            </Col>
                          </Row>
                        </div>
                      )
                    })} 
                  <Row>
                    <Col>
                      <Button onClick={addResponseBodyPairInput}><i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                    </Col>
                  </Row>
                </Form>

              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="submit" onClick={submitResponseBody}>
                  Save
                </Button>
              </CardFooter>
            </Card> : <></>}*/}

          {/* code */}
          {showUpdateCode ?
            <Card>
              <CardHeader><h5 className="title">Code</h5></CardHeader>
              <CardBody><Form>
                {codeDataCount
                  .map((index) => {
                    return (
                      <div>
                        <Row>
                          <Col>
                            <label>Language</label></Col>
                          <Col>
                            <label>Code Snippet</label></Col>
                          <Col></Col>
                        </Row>
                        <Row key={index} width="100%">
                          <Col>
                            <FormGroup>
                              <Input
                                id="key"
                                placeholder="Java"
                                type="text"
                                onChange={(e) => setCurrentCodeLanguage(e, index)}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Input
                                id="valueType"
                                placeholder="String"
                                type="textarea"
                                onChange={(e) => setCurrentCodeSnippet(e, index)}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <Button onClick={addCodeDataPair}><i className="tim-icons icon-check-2" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                            <Button onClick={removeCodeDataPairInput}><i className="tim-icons icon-simple-remove" style={{ color: "rgba(222, 35, 35, 1)" }} /></Button>
                          </Col>
                        </Row>
                      </div>
                    )
                  })}
                <Row>
                  <Col>
                    <Button onClick={addCodeDataPairInput}><i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} /></Button>
                  </Col>
                </Row>
              </Form>

              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="submit" onClick={submitCode}>
                  Save
                </Button>
              </CardFooter>
            </Card> : <></>}
        </div>
     
    </>
  );
}

export default RequestObjectDataManager;

