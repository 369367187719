import React, { useState, useEffect, useContext } from 'react';
import { getItem, getFileItem, deleteItem } from "../services/api.svs";
import { Auth } from 'aws-amplify';
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom";
import Logo from '../assets/img/react-logo.png';
import { Link } from "react-router-dom";

import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col
} from "reactstrap";
import { Button } from '@aws-amplify/ui-react';
import RequestObjectManager from './RequestObjectManager';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { KeycloakContext } from 'contexts/KeyCloakProvider';
import ApiProviderManager from './ApiProviderManager';

function ApiProvider(props) {

    const context = useContext(KeycloakContext);
    const { keycloak, initialized } = context
    const token = keycloak.token
    
    const [textColour, setColour] = useState("white")
    const [IsLoaded, setIsLoaded] = useState(false);
    const [apiprovider, setApiProvider] = useState([])
    const [apiObjects, setApiObjects] = useState([])
    const [showUpdate, setShowUpdate] = useState(false);

    const notificationAlertRef = React.useRef(null);
    const history = useHistory();

    const notify = (place, message, type) => {
        //  type = "primary";
        // type = "success";
        // type = "danger";
        // type = "warning";
        // type = "info";

        var options = {};
        options = {
            place: place,
            message: (
                message
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    useEffect(() => {
        const getRequestObject = async () => {
            const token = keycloak.token
            getItem(`/apiProvider/${props.location.state.apiProviderId}`, token).then(resp => {
                setApiProvider(resp.data)
                getItem(`/apiObject/sortId/${props.location.state.apiProviderId}`, token).then(resp => {
                    setApiObjects(resp.data)
                    setIsLoaded(true)
                }).catch(resp => {
                    setApiObjects([])
                    setIsLoaded(true)
                    let message = (<div>
                        <div>
                            Error retrieved getting the data
                        </div>
                    </div>)
                    notify("tl", message, "danger")
                })
                setIsLoaded(true)

            }).catch(resp => {
                setApiProvider([])
                setIsLoaded(true)
                let message = (<div>
                    <div>
                        Error retrieved getting the data
                    </div>
                </div>)
                notify("tl", message, "danger")
            })
        }

        if (initialized) {
            getRequestObject()
        }
       
    }, [initialized])

    // const getFullDataForUpdate = async () => {
    //     const token = keycloak.token
    //         getItem(`/requestobject/${props.location.state.requestObjectId}`, token).then(resp => {
    //             setRequestObjectUnfiltered(resp.data)
    //             setIsLoaded(true)
    //         }).catch(resp => {
    //             setRequestObjectUnfiltered([])
    //             setIsLoaded(true)
    //             let message = (<div>
    //                 <div>
    //                     Error retrieved getting the data
    //                 </div>
    //             </div>)
    //             notify("tl", message, "danger")
    //         })
    //     }
    


    // const onButtonClick = () => {
    //     const token = window.localStorage["Access_Token"]
    //     getFileItem(`storage/download/client?id=${props.location.state.contactId}`, token).then(response => {
    //         const filename = response.headers.get('Content-Disposition').split('filename=')[1];
    //         // response.blob().then(blob => {
    //         let url = window.URL.createObjectURL(new Blob([response.data]));
    //         let a = document.createElement('a');
    //         a.href = url;
    //         a.download = filename;
    //         a.click();
    //         //   }).catch(resp => {
    //         // });
    //     });
    // }

    const toggleShowUpdate = () => {
        setShowUpdate(showUpdate ? false : true)
    }

    const deleteRequestObject = () => {
        deleteItem(`/apiProvider/${props.location.state.apiProviderId}`, token).then(resp => {
            let state = "success"
            // notify("tl", message, "success")

            history.push("/api-providers", state);

        }).catch(resp => {
            setIsLoaded(true)
            let state = "danger"
            // notify("tl", message, "success")

            history.push("/api-providers", state);
        })
        //stop loading when data is fetched
    }

    return (
        <>
            <NotificationAlert ref={notificationAlertRef} />
            {IsLoaded ?
                <div className="content">
                    <Row>
                        <Col>
                            <Card className="card-plain">
                                <CardHeader><Row><Col><h2 className="title">{apiprovider.providerName}</h2></Col>

                                    <Col><div className="button-container">
                                        <Button className="btn-icon btn-round" onClick={toggleShowUpdate}>
                                            <i className="tim-icons icon-pencil" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                        </Button>&nbsp;&nbsp;&nbsp;
                                        <Button className="btn-icon btn-round" onClick={deleteRequestObject}>
                                            <i className="tim-icons icon-trash-simple" style={{ color: "red" }} />
                                        </Button>
                                    </div></Col></Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        {/* <CardText /> */}
                                        {/* <Col>
                                            <div className="card-description">
                                                <img
                                                    alt={`${requestobject.provider}`}
                                                    // className="avatar"
                                                    // src={`${process.env.REACT_APP_NOVAS_IMAGE_BASE}${requestobject.requestobjectUrl}`}
                                                    src={Logo}
                                                />
                                            </div>
                                        </Col> */}
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardBody>
                                                            <p className="description" style={{ marginBottom: "15px", marginLeft: "5px" }}>Contact Email: {apiprovider.contactEmail}</p>
                                                            
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                {/* <Col>
                                                    <Card>
                                                        <CardHeader><h4>Headers</h4></CardHeader>
                                                        <CardBody>

                                                            {apiprovider.headers.map((header) => {
                                                                return (
                                                                    <div className="description" style={{ marginTop: "5px", marginLeft: "5px" }} key={header.keyName}>{header.keyName}: {header.valueDataType}</div>
                                                                );
                                                            })}


                                                        </CardBody>
                                                    </Card>
                                                </Col> */}
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Card className='card-plain'>
                                                        <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: "transparent" }}>
                                                        <CardHeader><h4>APIs</h4></CardHeader>
                                                            <TableContainer sx={{ maxHeight: 700 }}>
                                                                <Table stickyHeader size="small" aria-label="sticky table" id="table-client">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>API Name</TableCell>
                                                                            <TableCell>Base Path</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {(apiObjects)
                                                                            .map((item) => {
                                                                                return (
                                                                                    <TableRow hover tabIndex={-1} key={item.apiObjectId}>
                                                                                        <TableCell style={{ color: `${textColour}` }}>{item.apiName}</TableCell>
                                                                                        <TableCell style={{ color: `${textColour}` }}>{item.basePath}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Paper>
                                                    </Card>
                                                </Col>
                                                
                                            </Row>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                            {showUpdate ? <Card>
                                <ApiProviderManager value={apiprovider} />
                            </Card> : <></>}
                        </Col>

                        {/* <Col>
                        <Card >
                            <CardBody>
                                
                                <div>
                                    <Input
                                        placeholder="Search by requestobject name..."
                                        onChange={(e) => onSearchData(e)}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                        <Card className='card-plain'>
                            <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: "transparent" }}>
                                <TableContainer sx={{ maxHeight: 700 }}>
                                    <Table stickyHeader aria-label="sticky table" id="table-client">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(filter ? filtered : requestobjects)
                                                .map((item) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={item.requestobjectId}>
                                                            <TableCell style={{ color: "white" }}>{item.requestobjectName}</TableCell>
                                                            <TableCell style={{ color: "white" }}>{item.description}</TableCell>
                                                            <TableCell>
                                                                <div className="button-container"> &nbsp;
                                                                    <Link to={{
                                                                        pathname: '/requestobjects',
                                                                        // search: '?',
                                                                        state: item
                                                                    }}><Button style={{ color: "white" }}>
                                                                            <i className="tim-icons icon-zoom-split" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                                                        </Button></Link>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Card>
                    </Col> */}
                    </Row>

                    {/* <Row>
                    <Card>
                        <CardBody><div className="button-container"><span style={{paddingRight: "2em"}}>Conversation on {startDate} from {startTime} to {endTime}</span>
                            
                                <Button className="btn-icon btn-round" onClick={onConversationClick}>
                                    <i className="tim-icons icon-cloud-download-93" />
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Row>

                {listItems} */}
                </div>
                // {/* } */}
                : <></>}
        </>
    );
}

export default ApiProvider;