/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* RequestObject Page: https://www.creative-tim.com/requestobject/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useContext } from 'react';
import { getItem, postFileItem, postItem, updateFileItem, updateItem } from "../services/api.svs";
import { useHistory } from "react-router-dom";

import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  FormText,
} from "reactstrap";
import { Auth } from 'aws-amplify';
import { Blocks } from 'react-loader-spinner';
import { Checkbox, FormControlLabel } from '@mui/material';
import { KeycloakContext } from 'contexts/KeyCloakProvider';

function RequestObjectLimitedManager(props) {

  const context = useContext(KeycloakContext);
  const { keycloak } = context
  const token = keycloak.token

  const [filledRequestObject, setFilledRequestObject] = useState(props.value)

  const [IsLoaded, setIsLoaded] = useState(false);
  const requestTypes = ['GET', 'POST', 'UPDATE', 'DELETE', 'REQUEST']

  // request object data
  const [requestObjectId, setId] = useState(filledRequestObject.requestObjectId);
  const [path, setPath] = useState(filledRequestObject.endpoint);
  const [apiObjects, setApiObjects] = useState([]);
  const [apiObject, setApiObject] = useState([]);
  const [requestType, setRequestType] = useState(filledRequestObject.requestType);
  const [pathVariable, setPathVariable] = useState(filledRequestObject.pathVariable);
  const [label, setLabel] = useState(filledRequestObject.label);
  const [responseBody, setResponseBody] = useState(filledRequestObject.responseBody != null ? filledRequestObject.responseBody : '');
  const hasFallback = useState(filledRequestObject.fallbackId != null && filledRequestObject.fallbackId != undefined);
  const [fallback, setFallback] = useState([]);
  const hasAuthRequestObject = useState(filledRequestObject.authRequestObjectId != null && filledRequestObject.authRequestObjectId != undefined);
  const [authRequestObject, setAuthRequestObject] = useState([]);
  const hasPostRequestObject = useState(filledRequestObject.postRequestObjectId != null && filledRequestObject.postRequestObjectId != undefined);
  const [postRequestObject, setPostRequestObject] = useState([]);
  const [requestObjects, setRequestObjects] = useState([]);
  const [addFallback, setAddFallback] = useState(false);
  const [showAuthRequest, setShowAuthRequest] = useState(false);
  const [showPostRequest, setShowPostRequest] = useState(false);
  const [csvUrl, setCsvUrl] = useState(filledRequestObject.csvUrl != null ? filledRequestObject.csvUrl : '');
  const [excelUrl, setExcelUrl] = useState(filledRequestObject.excelUrl != null ? filledRequestObject.excelUrl : '');
  const [description, setDescription] = useState(filledRequestObject.description != null ? filledRequestObject.description : '');
  const [icon, setIcon] = useState(filledRequestObject.icon != null ? filledRequestObject.icon : '');


  const notificationAlertRef = React.useRef(null);
  const history = useHistory();


  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    const getApiObject = async () => {
      getItem(`/requestObject/apiObject/` + filledRequestObject.requestObjectId, token).then(resp => {
        setApiObject(resp.data.apiObjectId)
        console.log(resp.data)
        // setApiObject(resp.data[0].id)
        setIsLoaded(true)

      }).catch(resp => {
        setApiObjects([])
        setIsLoaded(true)
        let message = (<div>
          <div>
            Error retrieved getting the data
          </div>
        </div>)
        notify("tl", message, "danger")
      })
    }

    // const getFallback = async () => {
    //   getItem(`/requestobject/` + filledRequestObject.fallbackId, token).then(resp => {
    //     setFallback(resp.data.id)
    //     console.log(resp.data)
    //     // setApiObject(resp.data[0].id)
    //     setIsLoaded(true)

    //   }).catch(resp => {
    //     setFallback([])
    //     setIsLoaded(true)
    //     let message = (<div>
    //       <div>
    //         Error retrieved getting the data
    //       </div>
    //     </div>)
    //     notify("tl", message, "danger")
    //   })
    // }

    const getApiObjects = async () => {
      getItem(`/apiObject`, token).then(resp => {
        setApiObjects(resp.data)
        // console.log(resp.data)
        setApiObject(resp.data[0].apiObjectId)
        setIsLoaded(true)

      }).catch(resp => {
        setApiObjects([])
        setIsLoaded(true)
        let message = (<div>
          <div>
            Error retrieved getting the data
          </div>
        </div>)
        notify("tl", message, "danger")
      })
    }
    getApiObject()
    getApiObjects()

    if (hasFallback === true) {
      console.log("hasFallback");
      // getFallback();
    }
  }, [])

  const toggleAddFallback = () => {
    {
      !addFallback ? (
        getItem(`/requestObject`, token).then(resp => {
          console.log(resp.data)
          setRequestObjects(resp.data)
          setFallback(resp.data[0].requestObjectId)
          setIsLoaded(true)

        }).catch(resp => {
          setRequestObjects([])
          setFallback('')
          setIsLoaded(true)
          let message = (<div>
            <div>
              Error retrieved getting the data
            </div>
          </div>)
          notify("tl", message, "danger")
        })) : (setFallback(''))
    }
    setAddFallback(addFallback ? false : true)
  }

  const toggleAddAuthRequestObject = () => {
    {
      !showAuthRequest ? (
        getItem(`/requestObject`, token).then(resp => {
          console.log(resp.data)
          setRequestObjects(resp.data)
          setAuthRequestObject(resp.data[0].requestObjectId)
          setIsLoaded(true)

        }).catch(resp => {
          setRequestObjects([])
          setAuthRequestObject('')
          setIsLoaded(true)
          let message = (<div>
            <div>
              Error retrieved getting the data
            </div>
          </div>)
          notify("tl", message, "danger")
        })) : (setAuthRequestObject(''))
    }
    setShowAuthRequest(showAuthRequest ? false : true)
  }

  const toggleAddPostRequestObject = () => {
    {
      !showPostRequest ? (
        getItem(`/requestObject`, token).then(resp => {
          console.log(resp.data)
          setRequestObjects(resp.data)
          setPostRequestObject(resp.data[0].requestObjectId)
          setIsLoaded(true)

        }).catch(resp => {
          setRequestObjects([])
          setPostRequestObject('')
          setIsLoaded(true)
          let message = (<div>
            <div>
              Error retrieved getting the data
            </div>
          </div>)
          notify("tl", message, "danger")
        })) : (setPostRequestObject(''))
    }
    setShowPostRequest(showPostRequest ? false : true)
  }

  const submit = () => {
    const formData = new FormData();
    formData.append("apiObjectId", apiObject);
    formData.append("path", path);
    formData.append("requestType", requestType);
    formData.append("pathVariable", pathVariable);
    formData.append("label", label);
    formData.append("description", description);
    formData.append("responseBody", responseBody)
    formData.append("icon", icon);
    formData.append("requestObjectId", requestObjectId)
    formData.append("fallbackId", fallback)
    formData.append("authRequestObjectId", authRequestObject)
    formData.append("postRequestObjectId", postRequestObject)
    formData.append("csvUrl", csvUrl);
    formData.append("excelUrl", excelUrl);
    const data = Object.fromEntries(formData.entries());
    updateItem('/requestObject', data, token).then(resp => {
      console.log('updating')
      setIsLoaded(true)
      let state = "success"

      history.push("/request-objects", state);
    }).catch(resp => {
      setIsLoaded(true)
      let state = "danger"

      history.push("/request-objects", state);
    })

  }

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <div className="content">
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h5 className="title">Update RequestObject</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>
                            API
                          </label>
                          <Input
                            id="apiObjectSelect"
                            name="apiObject"
                            type="select"
                            value={apiObject}
                            onChange={(e) => setApiObject(e.target.value)}
                          >
                            {apiObjects.map((item) => {
                              return (<option key={item.id} value={item.id}>
                                {item.apiName}
                              </option>);
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label>Endpoint</label>
                          <Input
                            placeholder="/endpoint"
                            type="text"
                            value={path}
                            onChange={(e) => setPath(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label>Label</label>
                          <Input
                            placeholder="Label"
                            type="text"
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Request Type</label>
                          <Input
                            id="apiObjectSelect"
                            name="apiObject"
                            type="select"
                            value={requestType}
                            onChange={(e) => setRequestType(e.target.value)}
                          >
                            {requestTypes.map((item) => {
                              return (<option key={item} value={item}>
                                {item}
                              </option>);
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label>Path Variable</label>
                          <Input
                            id="pathVariable"
                            placeholder="path variable"
                            value={pathVariable}
                            type="text"
                            onChange={(e) => setPathVariable(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label>Icon</label>
                          <Input
                            placeholder="fa icon"
                            type="text"
                            value={icon}
                            onChange={(e) => setIcon(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Description</label>
                          <Input
                            placeholder="Brief description"
                            type="textarea"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Response Body</label>
                          <Input
                            placeholder="Response Body Json"
                            type="textarea"
                            value={responseBody}
                            onChange={(e) => setResponseBody(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>CSV URL</label>
                          <Input
                            placeholder="https://link.to/csv_location"
                            type="text"
                            value={csvUrl}
                            onChange={(e) => setCsvUrl(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label>Excel URL</label>
                          <Input
                            placeholder="https://link.to/excel_location"
                            type="text"
                            value={excelUrl}
                            onChange={(e) => setExcelUrl(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* fallback */}
                    <Row>
                      <Col>
                        <Button onClick={toggleAddFallback}>Fallback</Button>
                      </Col>

                      <Col>
                        <Button onClick={toggleAddAuthRequestObject}>AuthObject</Button>
                      </Col>

                      <Col>
                        <Button onClick={toggleAddPostRequestObject}>PostObject</Button>
                      </Col>
                    </Row>
                    {addFallback == true ? <Row>
                      <Col>
                        <FormGroup>
                          <label>Fallback</label>
                          <Input
                            id="fallbackSelect"
                            name="fallbackObject"
                            type="select"
                            value={fallback}
                            onChange={(e) => setFallback(e.target.value)}
                          >
                            {requestObjects.map((item) => {
                              return (<option key={item.id} value={item.id}>
                                {item.label}
                              </option>);
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row> : <></>}
                    {/* authObject */}
                    <Row>
                    </Row>
                    {showAuthRequest == true ? <Row>
                      <Col>
                        <FormGroup>
                          <label>Auth Object</label>
                          <Input
                            id="authObjectSelect"
                            name="authObjectObject"
                            type="select"
                            value={authRequestObject}
                            onChange={(e) => setAuthRequestObject(e.target.value)}
                          >
                            {requestObjects.map((item) => {
                              return (<option key={item.id} value={item.id}>
                                {item.label}
                              </option>);
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row> : <></>}
                    {/* postObject */}
                    <Row>
                    </Row>
                    {showPostRequest == true ? <Row>
                      <Col>
                        <FormGroup>
                          <label>Post Object</label>
                          <Input
                            id="postObjectSelect"
                            name="postObjectObject"
                            type="select"
                            value={postRequestObject}
                            onChange={(e) => setPostRequestObject(e.target.value)}
                          >
                            {requestObjects.map((item) => {
                              return (<option key={item.id} value={item.id}>
                                {item.label}
                              </option>);
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row> : <></>}
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button className="btn-fill" color="primary" type="submit" onClick={submit}>
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>

        </div>
      ) : (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}>
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          /></div>

      )
      }
    </>
  );
}

export default RequestObjectLimitedManager;

