// import Dashboard from "views/Dashboard.js";
// import ProductTypes from "views/ProductTypes";
// import ProductType from "views/ProductType";
// import ProductTypeManager from "views/ProductTypeManager";
// import Messages from "views/Messages";
// import Message from "views/Message";
// import BestSellers from "views/BestSellers";
import RequestObjects from "views/RequestObjects";
import RequestObject from "views/RequestObject";
import RequestObjectManager from "views/RequestObjectManager";
import SQSManager from "views/SQSManager";
import ApiProviders from "views/ApiProviders";
import ApiProvider from "views/ApiProvider";
import ApiProviderManager from "views/ApiProviderManager";
import ApiObjects from "views/ApiObjects";
import ApiObjectManager from "views/ApiObjectManager";
import RequestObjectLimitedManager from "views/RequestObjectLimitedManager";
import Audit from "views/Audit";
// import Users from "views/Users.js";

var routes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   rtlName: "Dashboard",
  //   icon: "tim-icons icon-chart-pie-36",
  //   component: Dashboard,
  //   layout: "/admin",
  //   show: true
  // },
  // {
  //   path: "/product-types",
  //   name: "Product Types",
  //   rtlName: "Product Types",
  //   icon: "tim-icons icon-molecule-40",
  //   component: ProductTypes,
  //   layout: "/admin",
  //   show: true
  // },{
  //   path: "/product-type",
  //   name: "Product Type",
  //   rtlName: "Product Type",
  //   icon: "tim-icons icon-badge",
  //   component: ProductType,
  //   layout: "/admin",
  //   show: false
  // },
  {
    path: "/api-providers",
    name: "API Providers",
    rtlName: "API Providers",
    icon: "tim-icons icon-vector",
    component: ApiProviders,
    layout: "/admin",
    show: true
  }, 
  {
    path: "/api-provider",
    name: "API Provider",
    rtlName: "API Provider",
    icon: "tim-icons icon-atom",
    component: ApiProvider,
    layout: "/admin",
    show: false
  },
  {
    path: "/api-provider-manager",
    name: "API Provider Manager",
    rtlName: "API Provider Manager",
    icon: "tim-icons icon-link-72",
    component: ApiProviderManager,
    layout: "/admin",
    show: true
  },
  {
    path: "/api-objects",
    name: "API Objects",
    rtlName: "API Objects",
    icon: "tim-icons icon-molecule-40",
    component: ApiObjects,
    layout: "/admin",
    show: true
  }, 
  // {
  //   path: "/api-provider",
  //   name: "API Provider",
  //   rtlName: "API Provider",
  //   icon: "tim-icons icon-atom",
  //   component: ApiProvider,
  //   layout: "/admin",
  //   show: false
  // },
  {
    path: "/api-object-manager",
    name: "API Object Manager",
    rtlName: "API Object Manager",
    icon: "tim-icons icon-planet",
    component: ApiObjectManager,
    layout: "/admin",
    show: true
  }, 
  {
    path: "/request-objects",
    name: "Request Objects",
    rtlName: "Request Objects",
    icon: "tim-icons icon-atom",
    component: RequestObjects,
    layout: "/admin",
    show: true
  },
  {
    path: "/request-object",
    name: "Request Object",
    rtlName: "Request Object",
    icon: "tim-icons icon-badge",
    component: RequestObject,
    layout: "/admin",
    show: false
  },
  {
    path: "/request-object-manager",
    name: "Request Object Manager",
    rtlName: "Request Object Manager",
    icon: "tim-icons icon-badge",
    component: RequestObjectManager,
    layout: "/admin",
    show: true
  },
  {
    path: "/request-object-limited-manager",
    name: "Request Object Limited Manager",
    rtlName: "Request Object Limited Manager",
    icon: "tim-icons icon-badge",
    component: RequestObjectLimitedManager,
    layout: "/admin",
    show: false
  },
  {
    path: "/sqs-manager",
    name: "SQS Manager",
    rtlName: "SQS Manager",
    icon: "tim-icons icon-watch-time",
    component: SQSManager,
    layout: "/admin",
    show: true
  },
  {
    path: "/audit",
    name: "Audit",
    rtlName: "Audit",
    icon: "tim-icons icon-chart-pie-36",
    component: Audit,
    layout: "/admin",
    show: true
  }
  //{
  //   path: "/messages",
  //   name: "Messages",
  //   rtlName: "Messages",
  //   icon: "tim-icons icon-email-85",
  //   component: Messages,
  //   layout: "/admin",
  //   show: true
  // },{
  //   path: "/message",
  //   name: "Message",
  //   rtlName: "Message",
  //   icon: "tim-icons icon-email-85",
  //   component: Message,
  //   layout: "/admin",
  //   show: false
  // }

];
export default routes;
